
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    title: "Login",
    signIn: "Sign in",
    next: "NEXT",
    login: "LOGIN",
    description_signin: "Please insert your e-mail to access.\nIf you have been registered in our customer database you will receive a confirm code to the e-mail address specified.",
    description_selectCompany: "Select the Client",
    description_code: "You will now receive a temporary access code at the e-mail you specified.",
    code_sent: 'Code has been sent to',
    unknown_email: 'unknown e-mail',
    notification_error: 'In order to receive call notifications, please enabled notification for this app',
    sample_email: 'johndoe@mail.com',
    input_label_code: 'Confirm code',
    input_label_email: 'E-mail',
    resend_code_label: 'Resend code',
    change_email_label: 'Change e-mail',
    invalid_code_message: 'Invalid code or device not allowed',
    max_device_number_message: 'The maximum number of devices for this account has been reached',
  },
  it: {
    title: "Login",
    signIn: "Accedi",
    next: "AVANTI",
    login: "ENTRA",
    description_signin: "Inserisci il tuo indirizzo e-mail per accedere.\nSe sei registrato nei nostri archivi riceverai un codice di conferma all'indirizzo e-mail che hai indicato.",
    description_selectCompany: "Seleziona il Cliente",
    description_code: "Riceverai a breve un codice di accesso temporaneo all'indirizzo e-mail che hai indicato.",
    code_sent: 'Il codice di accesso è stato inviato a',
    unknown_email: 'e-mail sconosciuta',
    notification_error: 'Per ricevere le telefonate devi autorizzare l\'app a ricevere le notifiche',
    sample_email: 'mariorossi@mail.com',
    input_label_code: 'Codice di conferma',
    input_label_email: 'E-mail',
    resend_code_label: 'Rinvia il codice',
    change_email_label: 'Cambia e-mail',
    invalid_code_message: 'Il codice inserito non è valido o il dispositivo non è autorizzato',
    max_device_number_message: 'È stato raggiunto il numero massimo di device per questo account',
  }
});
  
  export default localeStrings;
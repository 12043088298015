
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    search_placeholder_text: 'Search',
    options_group_customers: 'Customers',
    options_group_contents: 'Contents',
    private_contents_title_text: 'Only private contents',
    public_contents_title_text: 'All contents',
    filter_button_cancel: 'Cancel',
    filter_button_apply: 'Apply',
  },
  it: {
    search_placeholder_text: 'Cerca',
    options_group_customers: 'Clienti',
    options_group_contents: 'Contenuti',
    private_contents_title_text: 'Solo contenuti privati',
    public_contents_title_text: 'Tutti i contenuti',
    filter_button_cancel: 'Annulla',
    filter_button_apply: 'Conferma',
  }
});

export default localeStrings;
import React from 'react';
import {connect} from 'react-redux';
import {Link, Page} from 'framework7-react';
import {v1 as uuid} from 'uuid';
import {AnalyticsRegister, ANALYTICS_SCREEN} from '../../analytics-register'
import localeStrings from './ar-web-local';
import {
    Vector3,
    Color3,
    ArcRotateCamera,
    PointLight,
    HemisphericLight,
    MeshBuilder,
    StandardMaterial,
    Texture,
    SceneLoader,
    Quaternion,
} from '@babylonjs/core';
import '@babylonjs/loaders';
import {GridMaterial} from "@babylonjs/materials";
import SceneComponent from 'babylonjs-hook';
import {PkLog} from '../../pikkart-cms/log';
import PkArViewInvolve from "../../pikkart-cms/ar-view/involve";
import styles from './ar-web.module.css';
import ArWebIntro from "./ar-web-intro";
import ArWebLoading from "./ar-web-loading";

// media type definitions
const MEDIA_TYPE_VIDEO = 'Video';
const MEDIA_TYPE_3D = 'Object3D';
const MEDIA_TYPE_AUDIO = 'Audio';
const MEDIA_TYPE_IMAGE = 'Image';
const MEDIA_TYPE_BUTTON = 'Button';

const NONE = "NONE";

// rotation conversion from cms to editor
const rotFromCmsToEditor = (v) => {
    const q = new Quaternion(0.0, 0.0, 0.0, 0.0);
    const cx = Math.cos(v.x * 0.5);
    const cy = Math.cos(v.y * 0.5);
    const cz = Math.cos(v.z * 0.5);
    const sx = Math.sin(v.x * 0.5);
    const sy = Math.sin(v.y * 0.5);
    const sz = Math.sin(v.z * 0.5);
    q.w = cx * cy * cz + sx * sy * sz;
    q.x = sx * cy * cz - cx * sy * sz;
    q.y = cx * sy * cz + sx * cy * sz;
    q.z = cx * cy * sz - sx * sy * cz;
    return q;
}

// convert media orientation data from cms to editor
const convertedOrientationForEditor = (orientation) => {
    const {x, y, z} = orientation;
    const orientationVector = new Vector3(-1 * x, y, -1 * z);
    const rotQuaternion = rotFromCmsToEditor(orientationVector);
    return rotQuaternion.toEulerAngles();
}

// scene media object constructor
class SceneMedia {
    constructor(media = {}) {
        this.id = media.id;
        this.name = media.name;
        this.mediaType = media.mediaType;
        this.fileUrl = media.fileUrl;
        this.editorPreviewUrl = media.editorPreviewUrl;
        this.uuid = uuid();
        this.mediaAction = media.mediaAction || {
            loop: false,
            alwaysRestart: false,
            autoReplay: false,
            actionType: NONE,
            actionValue: ''
        }
        this.pos = media.pos
            ? {
                ...media.pos,
                y: -1 * media.pos.y, // invert pos Y from Crs
            }
            : {x: 0, y: 0, z: 0};
        this.scale = media.scale || {x: 0.5, y: 0.5, z: 0.5};
        this.orientation = media.orientation
            ? convertedOrientationForEditor(media.orientation)
            : {x: 0, y: 0, z: 0};
    }
}

class ArWeb extends React.Component {

    constructor(props) {
        super(props);

        this.arId = this.props.arId;
        this.arType = this.props.arType;

        this.scene = undefined;
        this.camera = undefined;

        this.state = {
            fetched: false,
            loaded: false,
            toLoad: null,
            media: [],
        };
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.AR_WEB);

        // query scene media from api and add to state
        PkArViewInvolve.getSceneMedia(this.props.sceneId, this.props.subSceneId)
            .then((response) => {
                const {result, data} = response;
                if (result.success) {
                    if (Array.isArray(data) && data.length) {
                        // create sceneMedia objects array
                        const sceneMediaArray = data.map(m => new SceneMedia({...m, ...m.media}));
                        // update state with elements to load
                        this.setState({
                            fetched: true,
                            media: sceneMediaArray,
                            toLoad: data.length,
                        });
                    } else {
                        // no media elements to load so just fire the loaded state
                        this.setState({
                            fetched: true,
                            loaded: true,
                            toLoad: 0,
                        });
                    }
                } else {
                    PkLog.error(response);
                }
            });
    }

    onSceneReady = (scene) => {

        // reference this to local variable for use in callbacks
        let self = this;

        // assign scene and interactive components references
        this.scene = scene;

        // creates and positions a camera
        this.camera = new ArcRotateCamera("Camera", -Math.PI / 2, 3 * (Math.PI / 4), 2, new Vector3(0, 0, 0), this.scene);
        this.camera.minZ = 0.1;
        this.camera.wheelPrecision = 100;

        // targets the camera to scene origin
        this.camera.setTarget(Vector3.Zero());

        // attaches the camera to the canvas
        const canvas = this.scene.getEngine().getRenderingCanvas();
        this.camera.attachControl(canvas, true);

        // creates a light
        const light = new PointLight("light", new Vector3(0, 0, -10), this.scene);
        light.diffuse = new Color3(1, 1, 1);
        light.specular = new Color3(0, 0, 0);

        // creates another light
        const light1 = new HemisphericLight("light1", new Vector3(Math.PI, Math.PI, 0), this.scene);
        light1.intensity = 3;

        // set background color
        this.scene.clearColor = new Color3(1, 1, 1);

        // add ground grid
        const gridMaterial = new GridMaterial("gridMaterial", this.scene);
        if (!this.props.markerUrl) { // markerless editor
            gridMaterial.mainColor = new Color3(0.9, 0.9, 0.9);
            gridMaterial.lineColor = new Color3(0.8, 0.8, 0.8);
            gridMaterial.opacity = 1;
        } else {
            gridMaterial.mainColor = new Color3(0, 0, 0);
            gridMaterial.lineColor = new Color3(0.3, 0.3, 0.3);
            gridMaterial.opacity = 0.1;
        }
        gridMaterial.gridRatio = 0.1;
        gridMaterial.majorUnitFrequency = 1;
        gridMaterial.minorUnitVisibility = 0.1;
        gridMaterial.gridOffset = new Vector3(0, 0, 0);
        gridMaterial.backFaceCulling = false;

        const grid = MeshBuilder.CreateGround("grid", {
            width: 30,
            height: 30,
            subdivisions: 2,
        }, this.scene);
        grid.rotation.x = Math.PI / 2;
        grid.position.z = 0.0001;
        grid.material = gridMaterial;

        // add each media to scene
        this.state.media.forEach((media, index) => {
            this.addMesh(media, index);
        })
    }

    // add mesh to scene based on type
    addMesh = (media, index) => {
        const self = this;


        switch (media.mediaType) {
            case MEDIA_TYPE_AUDIO:
                // don't add mesh for audio type just remove from media to load count
                self.setState((s) => ({
                    toLoad: s.toLoad - 1,
                    ...(s.toLoad === 1 && {loaded: true})
                }));
                break;
            case MEDIA_TYPE_3D:
                // load 3D file
                SceneLoader.ImportMeshAsync(
                    '',
                    media.editorPreviewUrl,
                    '',
                    self.scene,
                    function () {
                    },
                    '.glb').then(function (newMeshes) {

                    // setup new 3D mesh
                    const new3DMesh = newMeshes.meshes[0];

                    // remove a count from items to load on mesh render
                    new3DMesh.onMeshReadyObservable.add(() => {
                        self.setState((s) => ({
                            toLoad: s.toLoad - 1,
                            ...(s.toLoad === 1 && {loaded: true})
                        }));
                    });

                    // remove possible duplicate
                    const duplicate = self.scene.getMeshByName(index);
                    if (duplicate) {
                        duplicate.dispose();
                    }

                    // assign index as name for future reference
                    new3DMesh.name = index;

                    // position
                    self.moveMesh(new3DMesh.name, {
                        pos: media.pos,
                        scale: media.scale,
                        orientation: media.orientation
                    });
                });
                break;
            case MEDIA_TYPE_VIDEO:
            case MEDIA_TYPE_IMAGE:
            case MEDIA_TYPE_BUTTON:
                let img = new Image();
                img.onload = () => {
                    // remove possible duplicate
                    const duplicate = self.scene.getMeshByName(index);
                    if (duplicate) {
                        duplicate.dispose();
                    }

                    // setup item marker
                    const marker = MeshBuilder.CreatePlane(index, {
                        height: img.height / img.width,
                        width: 1,
                    }, self.scene);

                    // remove a count from items to load on mesh render
                    marker.onMeshReadyObservable.add(() => {
                        self.setState((s) => ({
                            toLoad: s.toLoad - 1,
                            ...(s.toLoad === 1 && {loaded: true})
                        }));
                    });

                    // setup item marker texture
                    const markerMat = new StandardMaterial('markerMat', self.scene);
                    markerMat.diffuseTexture = new Texture(media.editorPreviewUrl, self.scene);
                    markerMat.diffuseTexture.hasAlpha = true;
                    markerMat.backFaceCulling = false;
                    marker.material = markerMat;

                    // position item marker
                    self.moveMesh(marker.name, {
                        pos: media.pos,
                        scale: media.scale,
                        orientation: media.orientation
                    });
                };
                img.src = media.editorPreviewUrl;
                break;
            default:
                break;
        }
    }

    // move mesh by uuid and new coords
    moveMesh = (index, coords) => {
        const mesh = this.scene.getMeshByName(index);
        const pos = new Vector3(parseFloat(coords.pos.x), parseFloat(coords.pos.y), parseFloat(coords.pos.z));
        const rot = new Vector3(parseFloat(coords.orientation.x), parseFloat(coords.orientation.y), parseFloat(coords.orientation.z));
        const scal = new Vector3(parseFloat(coords.scale.x), parseFloat(coords.scale.y), parseFloat(coords.scale.z));
        mesh.position = pos;
        mesh.rotationQuaternion = Quaternion.FromEulerVector(rot);
        mesh.scaling = scal;
    }

    onRender = (scene) => {
    }

    render() {
        const {fetched, loaded, toLoad, media} = this.state;
        const loadingProgress = (fetched && media.length)
            ? ((media.length - toLoad) * 100) / media.length
            : (fetched ? 100 : 0);

        return (
            <Page>
                <Link
                    back
                    iconIos="f7:arrow_left"
                    iconAurora="f7:arrow_left"
                    iconMd="material:arrow_back"
                    className='ar-web-back-button'/>
                <ArWebLoading loaded={loaded} loadingProgress={loadingProgress}/>
                <ArWebIntro loaded={loaded}/>
                {fetched &&
                    <SceneComponent className={styles.canvas}
                                    antialias
                                    observeCanvasResize={true}
                                    adaptToDeviceRatio={true}
                                    onSceneReady={this.onSceneReady}
                                    onRender={this.onRender}
                                    id="scene-canvas">
                    </SceneComponent>
                }
            </Page>
        )
    }
}

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {}
};
// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(ArWeb);

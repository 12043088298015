import React from 'react';
import { connect } from 'react-redux';
import CameraPhoto from './camera-photo';
import * as actions from '../../redux/actions';


class VideoRender extends React.Component {

    constructor(props) {
        super(props);

        this.cameraPhoto = CameraPhoto.getInstance();

        this.state = {
            streamReady: false,
            deleted: false,
        };
    }

    render() {
        this.cameraPhoto.onStreamReady = () => { this.setState({ streamReady: true }) }
        return (
            <div>
                <div className="video-container" deleted={this.state.deleted}></div>
                <div style={{width: '100vW', height: '100vh', position: 'absolute'}}></div>
            </div>
            )
    }

    componentWillUnmount = () => {
        // const videoContainers = document.getElementsByClassName("video-container");
        // for (var i = 0; i < videoContainers.length; i++) {
        //     videoContainers[i].setAttribute("deleted", "true");
        // }

        this.setState({ deleted: true })
    }

    componentDidUpdate = () => {

        const videos = document.getElementsByTagName("video");
        for (var i = 0; i < videos.length; i++) {
            videos[i].remove();
        }

        const videoContainers = document.getElementsByClassName("video-container");
        if (videoContainers.length > 0) {
            videoContainers[videoContainers.length - 1].appendChild(this.cameraPhoto.getVideoElement())
        }

        if (this.props.cameraLoaded) {
            this.props.cameraLoaded();
        }
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setImageCroppingCompleted: (imageCroppingCompleted) => dispatch(actions.setImageCroppingCompleted(imageCroppingCompleted)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoRender);
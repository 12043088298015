import React from 'react'
import { f7, Popup, Navbar, Link, NavTitle, NavLeft, NavRight, PageContent, Page, Block, Button, Row, Col } from 'framework7-react';
import { PkUI } from '../../pikkart-cms/ui/ui';
import { PkApiAuth } from '../../pikkart-cms/api/auth';
import { PkStorage } from '../../pikkart-cms/storage';
import { PkLog } from '../../pikkart-cms/log';
import LogoutLoader from './logout-loader';
import localeStrings from './logout-loader-local';

const LogOutPopup = (props) => {

    const logout = () => {
        setTimeout(() => {
            PkApiAuth.doLogout()
                .then(() => {
                    props.logoutDialogConfirmed();
                })
                .catch(() => {
                    PkLog.log('error logging out');
                });
        }, 200);
    }

    const profile = PkStorage.getProfile();
    let logoutLoader = null;
    let logoutCard = null;

    if (!PkApiAuth.isAuthenticated()) {
        logoutLoader = (<LogoutLoader router={props.context ? props.context.$f7router : null}></LogoutLoader>);
    }
    else {
        logoutCard = (
            <div className="elevation-6" style={{ margin: '15px', padding: '1px' }}>
                <Block textAlign="center">
                    <Row>
                        <Col width="30" style={{ textAlign: 'right' }}>
                            <img alt={profile.Name ? profile.Name : "N.A."} src={PkUI.getAvatar(profile.Name)} width="48" style={{ borderRadius: "50%" }} />
                        </Col>
                        <Col width="70">
                            <strong style={{ display: "block", fontSize: '120%' }}>{profile.Name ? profile.Name : "N.A."}</strong>
                            <div style={{ display: profile.Company ? "block" : "none" }}>{profile.Company ? profile.Company.Name : null}</div>
                            <div style={{ display: profile.CompanyRole ? "block" : "none" }}>{profile.CompanyRole}</div>
                            <div style={{ display: profile.Email ? "block" : "none" }}>{profile.Email}</div>
                        </Col>
                    </Row>
                </Block>
                <div style={{ textAlign: 'right' }}>
                    <Button onClick={(e) => {
                        f7.dialog.confirm(localeStrings.logout_alert_text, localeStrings.logout_alert_title, logout)
                    }}>{localeStrings.logout_button}</Button>
                </div>
            </div>
        );
    }

    const getIconTag = () => {
        return (<img slot="media" src={props.logo}
            className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />
        )
    }

    const getSpacingIcon = () => {
        if (props.showChangeCustomerButton || props.showLogoutButton || props.searchBarEnabled) { return ""; }
        else {
            return (
                <img slot="media" src='./img/spacing_icon.png'
                    className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />
            )
        }
    }

    return (
        <Popup
            navBarTitle={props.navBarTitle}
            opened={props.opened}
            tabletFullscreen={true}>
            <Page pageContent={true} colorTheme="custom">
                <Navbar mdCenterTitle={true}>
                    <NavLeft>
                        <Link popupClose onClick={props.closeIconClick} iconOnly={true} color="black" iconIos="f7:xmark" iconAurora="f7:xmark" iconMd="material:close" />
                    </NavLeft>
                    <NavTitle>{props.navBarTitle}</NavTitle>
                    <NavRight>
                        {JSON.parse(props.showNavRight) ? getIconTag() : getSpacingIcon()}
                    </NavRight>
                </Navbar>

                <PageContent>
                    {logoutLoader}
                    {logoutCard}
                    {/* {alternativeAccounts} */}
                </PageContent>
            </Page>
        </Popup>);
}

export default LogOutPopup;
/* eslint-disable import/no-anonymous-default-export */
import Landing from './pre-pages/landing/landing.jsx'
import Main from './pages/main/main.jsx'
import Splash from './pre-pages/splash/splash.jsx'

import Ar from './pages/ar/ar.jsx'
// import ArSceneList from './pages/ar-scenes/ar-scene-list.jsx'
import AssetRecognitionResult from './pages/asset-recognition/asset-recognition-result.jsx'
import Profilo from './pages/profile/profilo.jsx'
import Credits from './pages/credits/credits.jsx'
import Tutorial from './pages/tutorial/tutorial.jsx'
import PreTutorial from './pages/pre-tutorial/pre-tutorial.jsx'
import RefarmTutorial from './pages/tutorial/refarm-tutorial.jsx'
import MembersArea from './pages/members-area/members-area.jsx'
import DiscoverViews from './pages/discover-editor/discover-views.jsx'
import DiscoverContentDetail from './pages/discover-editor/discover-content-detail.jsx'
import Notifications from './pages/notifications/notifications.jsx'
import Login from './pages/login/login.jsx'
import ContactUs from './pages/contact-us/contact-us.jsx'
import AssetRecognition from './pages/asset-recognition/asset-recognition.jsx'
import ProductList from './pages/asset-recognition/product-list.jsx'
import PhotoGallery from './pages/photo-gallery/photo-gallery.jsx'

import ChecklistResult from './pages/checklist-results/checklist-result.jsx'
import ChecklistResultList from './pages/checklist-results/checklist-results-list.jsx'

import MenuList from './contents/menu-list.jsx'
import ContentList from './contents/content-list.jsx'
import ContentKML from './contents/content-kml.jsx'

import ContentDetail from './contents/content-detail.jsx'
import RequestedContentDetail from './contents/requested-content-detail.jsx'
import Commons from './commons'

import FormDebugResults from './pages/asset-recognition/form-debug-results.jsx'

import { AnalyticsRegister } from './analytics-register'
import { PkLog } from './pikkart-cms/log'
import DiscoverViewDetail from './pages/discover-editor/discover-view-detail.jsx'
import ErrorNoConnection from './error-no-connection/error-no-connection.jsx'

import ArWeb from './pages/ar-web/ar-web.jsx'
import ArWebIndex from "./pages/ar-web/ar-web-index";

export default [
  {
    path: '/',
    name: 'root',
    component: Landing,
    alias: '/index.html'
  },
  {
    name: 'splash',
    path: '/splash/',
    component: Splash
  },
  {
    name: 'main',
    path: '/main/',
    component: Main
  },
  {
    name: 'profilo',
    path: '/profilo',
    component: Profilo
  },
  {
    name: 'ar',
    path: '/ar',
    component: Ar
  },
  // {
  //   name: 'arscenes-nootp',
  //   path: '/arscenes-nootp',
  //   component: ArSceneList
  // },
  // {
  //   name: 'arscenes',
  //   path: '/arscenes',
  //   component: ArSceneList
  // },
  {
    name: 'asset-recognition-result',
    path: '/asset-recognition-result',
    component: AssetRecognitionResult
  },
  {
    name: 'form-debug-results',
    path: '/form-debug-results',
    component: FormDebugResults,
  },
  {
    name: 'settings',
    path: '/settings',
    component: Profilo
  },
  {
    name: 'Credits',
    path: '/credits',
    component: Credits
  },
  {
    name: 'Tutorial',
    path: '/tutorial',
    component: Tutorial
  },
  {
    name: 'PreTutorial',
    path: '/pre-tutorial',
    component: PreTutorial
  },
  {
    name: 'RefarmTutorial',
    path: '/refarm-tutorial',
    component: RefarmTutorial
  },
  {
    name: 'discover-views',
    path: '/discover-views',
    component: DiscoverViews
  },
  {
    name: 'discover-content-detail',
    path: '/discover-content-detail',
    component: DiscoverContentDetail
  },
  {
    name: 'discover-view-detail',
    path: '/discover-view-detail',
    component: DiscoverViewDetail
  },
  {
    name: 'login',
    path: '/login/',
    component: Login
  },
  {
    name: 'Notifications',
    path: '/notifications',
    component: Notifications
  },
  {
    name: 'ContactUs',
    path: '/contact',
    component: ContactUs
  },
  {
    name: 'Content-kml',
    path: '/content-kml',
    component: ContentKML
  },
  {
    name: 'Asset-Recognition',
    path: '/asset-recognition',
    async: function (props) {
      // const routeTo = props.to;
      /**
       * per ora mantengo la logica vecchia ma se devo 
       * fare la divisione andorid/ios uso l'async
       */
      const resolve = props.resolve;
      resolve({ component: AssetRecognition });
    }
  },
  {
    name: 'No connection',
    path: '/no-connection/',
    component: ErrorNoConnection,
  },
  {
    name: 'ArWeb',
    path: '/ar-web',
    component: ArWeb,
  },
  {
    name: 'ArWebIndex',
    path: '/ar-web-index',
    component: ArWebIndex,
  },
  {
    path: '/photo-gallery-result/',
    component: PhotoGallery
  },
  {
    path: '/content-detail/:contentId',
    component: ContentDetail,

    async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
    }
  },
  {
    path: '/checklist-result/',
    component: ChecklistResult,

    async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
    }
  },
  {
    path: '/requested-content-detail/:contentId',
    component: RequestedContentDetail,

    async: function (routeTo, routeFrom, resolve, reject) {
      // Requested route
    }
  },
  {
    name: 'MembersArea',
    path: '/members-area',
    async: function (props) {
      const routeTo = JSON.parse('{"query":{"cid":"15258"},"params":{},"url":"/discover?cid=15258","path":"/discover","route":{"path":"(.*)"}}');
      const resolve = props.resolve;

      PkLog.log("routeTo=", routeTo);

      let menuStr = localStorage.getItem('menu');
      if (menuStr != null) {
        let menu = JSON.parse(localStorage.getItem('menu'));


        // let menuItem = null;
        // menu.map((item, index) => {
        //   console.log(item);
        //   if (item.code === '/arscenes') {
        //     menuItem = item;
        //   }
        // });

        let menuItem = Commons.getMenuItemFromCode('/arscenes', menu);

        if (menuItem) {
          let resolveArgs = { component: MembersArea };
          let resolveOptions = { props: { 'menuItem': menuItem, ['c' + menuItem.catIndex]: menuItem.id } }

          resolve(resolveArgs, resolveOptions);
        }
        else {
          resolve({ component: MembersArea });
        }
      }
      else {
        resolve({ component: MembersArea });
      }
    },
  },
  {
    name: 'result',
    path: '/result',
    async: function (props) {
      const routeTo = props.to;
      const resolve = props.resolve;

      PkLog.log("routeTo=", routeTo);

      let menuStr = localStorage.getItem('menu');
      if (menuStr != null) {
        let menu = JSON.parse(localStorage.getItem('menu'));

        let menuItem = Commons.getMenuItemFromRoute(routeTo, menu);

        if (menuItem) {

          let resolveArgs = { component: ProductList };
          let resolveOptions = { props: { 'menuItem': menuItem, ['c' + menuItem.catIndex]: menuItem.id } }

          AnalyticsRegister.setCurrentScreen(menuItem.title);
          resolve(resolveArgs, resolveOptions);
        }
        else {
          resolve({ component: ProductList });
        }
      }
      else {
        resolve({ component: ProductList });
      }
    }
  },
  {
    name: 'Results',
    path: '/jresults',
    component: ChecklistResultList
  },

  // Default route (404 page). MUST BE THE LAST
  {
    path: '(.*)',
    async: function (props) {
      const routeTo = props.to;
      const resolve = props.resolve;

      PkLog.log("routeTo=", routeTo);

      let menuStr = localStorage.getItem('menu');
      if (menuStr != null) {
        let menu = JSON.parse(localStorage.getItem('menu'));

        let menuItem = Commons.getMenuItemFromRoute(routeTo, menu);

        if (menuItem) {

          let resolveArgs = { component: menuItem.items.length > 0 ? MenuList : ContentList };
          let resolveOptions = { props: { 'menuItem': menuItem, ['c' + menuItem.catIndex]: menuItem.id } }

          AnalyticsRegister.setCurrentScreen(menuItem.title);
          resolve(resolveArgs, resolveOptions);
        }
        else {
          resolve({ component: ContentList });
        }
      }
      else {
        resolve({ component: ContentList });
      }
    }
  },
];

import React from 'react';
import PikkartTutorial from '../components/pk-tutorial';
import menuLocaleStrings from '../pages/tutorial/tutorial-local';
import sceneViewLocaleStrings from '../content-views/ar-scene-view/ar-scene-view-local';
import discoverContentDetailLocaleString from '../contents/content-detail-local';
import arLocaleStrings from '../pages/ar/ar-local';
import arSceneListLocaleStrings from '../pages/ar-scenes/ar-scenes-local';
import arLogoMainPopupLocaleString from '../pages/main/main-local';

export class TutorialHelper {
    /**
     * Tutorial del menu laterale
     */
    static getMenuTutorial = () => {

        const slides = [
            { image: './img/tutorial_ar_generic_1.png', text: menuLocaleStrings.tutorial_1 },
            { image: './img/tutorial_ar_marker_2.png', text: menuLocaleStrings.tutorial_2 },
        ];

        const showToggle = false;
        const toggleText = menuLocaleStrings.tutorial_toggle_label;
        const showButton = false;
        const buttonText = menuLocaleStrings.tutorial_button_label;

        return (
            <PikkartTutorial slides={slides} toggleText={toggleText} buttonText={buttonText} showToggle={showToggle} showButton={showButton} />)
    }

    /**
     * Tutorial del menu laterale con scelta su SceneView
     */
    static getMenuSceneViewTutorial = () => {

        const slides = [
            { image: './img/tutorial_ar_generic_1.png', text: sceneViewLocaleStrings.tutorial_1 },
            { image: './img/tutorial_ar_generic_2.png', text: sceneViewLocaleStrings.tutorial_2 },
            { image: './img/tutorial_ar_generic_3.png', text: sceneViewLocaleStrings.tutorial_3 },
        ];

        const showToggle = false;
        const toggleText = menuLocaleStrings.tutorial_toggle_label;
        const showButton = false;
        const buttonText = menuLocaleStrings.tutorial_button_label;

        return (
            <PikkartTutorial slides={slides} toggleText={toggleText} buttonText={buttonText} showToggle={showToggle} showButton={showButton} />)
    }
    
    /**
     * Tutorial del menu laterale con scelta su PlanarAndDiscover
     */
    static getMenuPlanarAndDiscoverTutorial = () => {

        const slides = [
            { image: './img/tutorial_ar_marker_1.png', text: arLocaleStrings.tutorial_1 },
            { image: './img/tutorial_ar_marker_2.png', text: arLocaleStrings.tutorial_2 },
        ];

        const showToggle = false;
        const toggleText = menuLocaleStrings.tutorial_toggle_label;
        const showButton = false;
        const buttonText = menuLocaleStrings.tutorial_button_label;

        return (
            <PikkartTutorial slides={slides} toggleText={toggleText} buttonText={buttonText} showToggle={showToggle} showButton={showButton} />)
    }


    /**
     * Tutorial del popup prima di una scena ar SLAM
     */
    static getSceneViewTutorial = (toggleClicked, buttonClicked) => {
        const slides = [
            { image: './img/tutorial_ar_generic_1.png', text: sceneViewLocaleStrings.tutorial_1 },
            { image: './img/tutorial_ar_generic_2.png', text: sceneViewLocaleStrings.tutorial_2 },
            { image: './img/tutorial_ar_generic_3.png', text: sceneViewLocaleStrings.tutorial_3 },
        ];

        const showToggle = true;
        const toggleText = sceneViewLocaleStrings.tutorial_toggle_label;
        const showButton = true;
        const buttonText = sceneViewLocaleStrings.tutorial_button_label;

        return (
            <PikkartTutorial
                slides={slides}
                toggleText={toggleText}
                buttonText={buttonText}
                showToggle={showToggle}
                showButton={showButton}
                toggleClicked={toggleClicked}
                buttonClicked={buttonClicked} />)
    }

    /**
     * Tutorial del popup prima di un modello discover
     */
    static getDiscoverContentDetailTutorial = (toggleClicked, buttonClicked) => {

        const slides = [
            { image: './img/tutorial_ar_marker_1.png', text: arLocaleStrings.tutorial_1 },
            { image: './img/tutorial_ar_marker_2.png', text: arLocaleStrings.tutorial_2 },
        ];

        const showToggle = true;
        const toggleText = discoverContentDetailLocaleString.tutorial_toggle_label;
        const showButton = true;
        const buttonText = discoverContentDetailLocaleString.tutorial_button_label;

        return (
            <PikkartTutorial
                slides={slides}
                toggleText={toggleText}
                buttonText={buttonText}
                showToggle={showToggle}
                showButton={showButton}
                toggleClicked={toggleClicked}
                buttonClicked={buttonClicked} />)
    }
    
    static getArTutorial = (toggleClicked, buttonClicked) => {
        const slides = [
            { image: './img/tutorial_ar_marker_1.png', text: arLocaleStrings.tutorial_1 },
            { image: './img/tutorial_ar_marker_2.png', text: arLocaleStrings.tutorial_2 },
        ];

        const showToggle = true;
        const toggleText = arLocaleStrings.tutorial_toggle_label;
        const showButton = true;
        const buttonText = arLocaleStrings.tutorial_button_label;

        return (
            <PikkartTutorial
                slides={slides}
                toggleText={toggleText}
                buttonText={buttonText}
                showToggle={showToggle}
                showButton={showButton}
                toggleClicked={toggleClicked}
                buttonClicked={buttonClicked} />)
    }

    static getArMarkerRecognitionTutorial = (toggleClicked, buttonClicked) => {

        const slides = [
            { image: './img/tutorial_ar_marker_1.png', text: arSceneListLocaleStrings.tutorial_recognition_1 },
            { image: './img/tutorial_ar_marker_2.png', text: arSceneListLocaleStrings.tutorial_recognition_2 },
        ];

        const showToggle = true;
        const toggleText = arSceneListLocaleStrings.tutorial_toggle_label;
        const showButton = true;
        const buttonText = arSceneListLocaleStrings.tutorial_button_label;

        return (
            <PikkartTutorial
                slides={slides}
                toggleText={toggleText}
                buttonText={buttonText}
                showToggle={showToggle}
                showButton={showButton}
                toggleClicked={toggleClicked}
                buttonClicked={buttonClicked} />)
    }

    static getArLogoMainPopupTutorial = (toggleClicked, buttonClicked) => {
        const slides = [
            { image: './img/tutorial1-ar.png', text: arLogoMainPopupLocaleString.tutorial_1 },
            { image: './img/tutorial2.png', text: arLogoMainPopupLocaleString.tutorial_2 },
        ];

        const showToggle = true;
        const toggleText = arLogoMainPopupLocaleString.tutorial_toggle_label;
        const showButton = true;
        const buttonText = arLogoMainPopupLocaleString.tutorial_button_label;

        return (
            <PikkartTutorial
                slides={slides}
                toggleText={toggleText}
                buttonText={buttonText}
                showToggle={showToggle}
                showButton={showButton}
                toggleClicked={toggleClicked}
                buttonClicked={buttonClicked} />)
    }
}

export default TutorialHelper;
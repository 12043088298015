import {
    f7,
    Button,
    Block,
    BlockTitle,
    Link,
    List,
    ListItem,
    Navbar,
    NavLeft,
    NavRight,
    NavTitle,
    Page,
    PageContent,
    Popover,
    Sheet,
    Tab,
    Tabs,
    Toolbar,
    ListInput,
    Icon,
    ListItemCell,
    Range,
    Row,
    Col
} from 'framework7-react';
import React from 'react';
import { connect } from 'react-redux';
import AnalyticsRegister, { ANALYTICS_SCREEN } from '../../analytics-register';
import localeStrings from './discover-editor-local';
import PkArDiscover from '../../pikkart-cms/ar/discover';
import Draggable from 'react-draggable';
import CanvasDraw from 'react-canvas-draw';
import PkLog from '../../pikkart-cms/log';

class DiscoverViewDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pois: [],
            popoverOptions: [],
            contentGroup: [
                { id: 1, name: "Scheda Informativa" },
                { id: 3, name: "Luogo" },
                { id: 5, name: "Business Card" },
                { id: 6, name: "Company" },
                { id: 7, name: "Discover Model" },
                { id: 8, name: "Markerless" },
                { id: 9, name: "Discover Key Point" },
                { id: 10, name: "Itinerario" },
                { id: 11, name: "Scena AR" }],
            selectedPoI: null,
            imageWidth: 0,
            imageHeight: 0,
            lastX: 0,
            lastY: 0,
            newPoI: false,
            movement: false,
            prevContentId: null,
            colorPickerClosed: false,
            error: false,
            contentSheetOpened: false,
            showMaskButtons: false,
            drawingRadius: 20,
            brushRadius: 10,
            layer: this.props.layer
        }
        this.deleteView = this.deleteView.bind(this);
        this._diff = null;
    }


    /**
     * Funzione che crea un nuovo punto di interesse
     * 
     * @param {object} e evento del click sull'immagine per la creazione di un punto di interesse 
     */
    createPoI = (e) => {
        if (!this.state.selectedPoI && e.target.id === "pois-container") {
            this.changePopoverOptionsAvailable("");

            console.log('poi_data', 'width ' + document.getElementById("pois-image").getBoundingClientRect().width);
            console.log('poi_data', 'height ' + document.getElementById("pois-image").getBoundingClientRect().height);
            console.log('poi_data', 'left ' + document.getElementById("pois-image").getBoundingClientRect().left);
            console.log('poi_data', 'top ' + document.getElementById("pois-image").getBoundingClientRect().top);
            console.log('poi_data', 'clickX ' + e.pageX);
            console.log('poi_data', 'clickY ' + e.pageY);

            // let temp_posX = window.innerWidth <= 1024 ?
            //     e.pageX - document.querySelector("#pois-container").offsetLeft - 16 - window.getComputedStyle(document.getElementById("pois-image")).marginLeft.substr(0, -2) - 10
            //     :
            //     (e.pageX - document.querySelector("#pois-container").offsetLeft - 16 - window.getComputedStyle(document.getElementById("pois-image")).marginLeft.substr(0, -2) - 10) - (window.innerWidth * 15 / 100);
            // // let temp_posY = e.pageY - document.querySelector("#pois-container").offsetTop - document.querySelector(".block").offsetTop - 10;
            // let temp_posY = e.clientY - document.getElementById('pois-container').offsetHeight / 2;

            let temp_posX = e.pageX - document.getElementById("pois-image").getBoundingClientRect().left;
            let temp_posY = e.pageY - document.getElementById("pois-image").getBoundingClientRect().top


            let posX = temp_posX * this.state.imageWidth / document.getElementById("pois-image").getBoundingClientRect().width - document.getElementById("pois-image").getBoundingClientRect().left;
            //= temp_posY * this.state.imageHeight / document.getElementById("pois-image").getBoundingClientRect().height;
            const posYWithOffsets = temp_posY * this.state.imageHeight / document.getElementById("pois-image").getBoundingClientRect().height;
            let posY = posYWithOffsets - document.querySelector("#pois-container").offsetTop - document.querySelector(".block").offsetTop;// - 20;

            console.log('imageHeight ' + this.state.imageHeight + ' e.pageY ' + e.pageY + ' e.posY ' + posY);

            console.log('color picker custom', document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].children[1].children[0]);
            // document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].children[1].children[0].style.display = "none";
            document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].addEventListener("click", () => {
                console.log('color picker custom', document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].children[1].children[0]);
                document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].children[1].children[0].click();
            });

            console.log('poi_data', 'posX ' + posX);
            console.log('poi_data', 'posY ' + posY);
            let tempnewpoi = {
                keypointUniqueId: "",
                contentId: "",
                posX: posX,
                posY: posY,
                color: "#ff0000",
                viewUniqueId: this.props.view.uniqueId
            };
            this.setState({
                pois: [...this.state.pois, tempnewpoi],
                selectedPoI: tempnewpoi,
                newPoI: true,
                contentSheetOpened: true,
            });
        }
    }

    /**
     * Funzione per cambiare colore al punto di interesse
     * 
     * @param {object} e evento del click per cambiare colore
     */
    setColorSelectedPoI = (e) => {
        console.log('set color selected poi', e);
        if (this.state.selectedPoI) {
            if (!this.state.error) {
                let selectedPoi = this.state.selectedPoI;
                selectedPoi.color = e.hex;
                this.setState({ selectedPoi: selectedPoi });
                this.state.pois.filter(poi => {
                    return poi.keypointUniqueId === this.state.selectedPoI.keypointUniqueId
                })[0].color = e.hex;

                if (document.getElementsByClassName("color-picker-popover").length > 0 && !this.state.colorPickerClosed) {
                    this.setState({ colorPickerClosed: true });
                    f7.colorPicker.get('.color-picker').on('close', () => {
                        this.savePoI(false);
                    })
                }
            }
        }
    }

    /**
     * Funzione che salva il punto di interesse
     */
    savePoI = (resetPoi) => {
        const resolveSaving = () => {
            this.hidePreloader();
            if (resetPoi)
                this.setState({ selectedPoI: null })
        }

        const errorSaving = (error) => {
            this.showToast(localeStrings.saving_poi_error);
            PkLog.error('discover-view-detail.jsx: ' + JSON.stringify(error));
        }

        this.showPreloader();

        // let posX = this.state.selectedPoI.posX * this.state.imageWidth / (document.getElementById("pois-image").getBoundingClientRect().width - document.getElementById("pois-image").getBoundingClientRect().left);
        // // let posY = this.state.selectedPoI.posY * this.state.imageHeight / document.getElementById("pois-image").getBoundingClientRect().height;
        // const posYWithOffsets = this.state.selectedPoI.posY * this.state.imageHeight / document.getElementById("pois-image").getBoundingClientRect().height;
        // let posY = posYWithOffsets - document.querySelector("#pois-container").offsetTop - document.querySelector(".block").offsetTop - 20;
        // posY = posY * document.getElementById("pois-image").getBoundingClientRect().height / this.state.imageHeight


        const poiRadius = document.getElementById(this.state.selectedPoI.keypointUniqueId) ?
            (document.getElementById(this.state.selectedPoI.keypointUniqueId).offsetWidth / 2) / 2
            : 0;

        // let posX = poiRadius + this.state.selectedPoI.posX * this.state.imageWidth / (document.getElementById("pois-image").getBoundingClientRect().width - document.getElementById("pois-image").getBoundingClientRect().left);
        let posX = poiRadius + this.state.selectedPoI.posX * this.state.imageWidth / (document.getElementById("pois-image").getBoundingClientRect().width);
        let posY = poiRadius + (this.state.selectedPoI.posY * this.state.imageHeight / document.getElementById("pois-image").getBoundingClientRect().height);



        //TODO: capire perché prima calcolo posx e poi passo un vaolore diverso al cms
        if (this.state.selectedPoI.contentId !== "") {
            if (this.state.newPoI) {
                PkArDiscover.saveModelViewKeyPoint(
                    this.props.view.uniqueId,
                    this.state.selectedPoI.contentId,
                    this.state.selectedPoI.posX,
                    this.state.selectedPoI.posY,
                    this.state.selectedPoI.color
                ).then(res => {
                    if (res.result.success) {
                        let currentPois = this.state.pois;
                        const poiToOverridePosition = currentPois.findIndex(poi => poi.keypointUniqueId === "");
                        currentPois[poiToOverridePosition] = res.data;
                        this.setState({
                            pois: currentPois,
                            selectedPoI: res.data,
                            newPoI: false
                        });
                    } else {
                        this.setState({ error: true });
                        errorSaving(res.result);
                    }
                })
                    .catch((err) => {
                        this.setState({ error: true });
                        errorSaving(err);
                    })
                    .finally(() => {
                        resolveSaving();
                    });
            } else if (!this.state.movement) {
                PkArDiscover.saveModelViewKeyPoint(
                    this.props.view.uniqueId,
                    this.state.selectedPoI.contentId,
                    this.state.selectedPoI.posX,
                    this.state.selectedPoI.posY,
                    this.state.selectedPoI.color
                ).then(res => {
                    if (res.result.success) {
                        let currentPois = this.state.pois;
                        const poiToOverridePosition = currentPois.findIndex(poi => poi.keypointUniqueId === "");
                        currentPois[poiToOverridePosition] = res.data;
                        this.setState({
                            pois: currentPois,
                            selectedPoI: res.data,
                            error: false
                        });
                        if (this.state.prevContentId !== this.state.selectedPoI.contentId) {
                            PkArDiscover.deleteModelViewKeyPoint(this.props.view.uniqueId, this.state.prevContentId);
                        }
                    } else {
                        this.setState({ error: true });

                        errorSaving(res.result);
                    }
                })
                    .catch((err) => {
                        this.setState({ error: true });
                        errorSaving(err);
                    })
                    .finally(() => {
                        resolveSaving();
                    });
            } else {
                PkArDiscover.saveModelViewKeyPoint(
                    this.props.view.uniqueId,
                    this.state.selectedPoI.contentId,
                    posX,
                    posY,
                    this.state.selectedPoI.color
                ).then(res => {
                    if (res.result.success) {
                        let currentPois = this.state.pois;
                        const poiToOverridePosition = currentPois.findIndex(poi => poi.keypointUniqueId === "");
                        currentPois[poiToOverridePosition] = res.data;
                        this.setState({
                            pois: currentPois,
                            selectedPoI: res.data,
                            movement: false,
                            error: false
                        });
                    } else {
                        this.setState({ error: true });

                        errorSaving(res.result);
                    }
                })
                    .catch((err) => {
                        errorSaving(err);
                    })
                    .finally(() => {
                        resolveSaving();
                    });
            }
        }
        else {
            resolveSaving();
        }
    }

    /**
     * Funzione per cambiare il valore di default e disabilita opzioni del tag select
     * 
     * @param {string} value valore che seleziona la option selezionata
     */
    changePopoverOptionsAvailable = (value) => {
        const selectContentId = document.getElementById("selectContentId");

        const opts = selectContentId.options;

        const self = this;

        Array.prototype.forEach.call(opts, function (option, index) {

            option.disabled = false;

            if (option.value === value) {
                selectContentId.selectedIndex = index;
                option.disabled = false;
                if (document.getElementsByClassName("item-after")[0]) {
                    document.getElementsByClassName("item-after")[0].innerHTML = option.text
                }
            }

            console.log('option ' + (index + 1) + ':', option.value);
            self.state.pois.forEach(e => {
                if (e.contentId === Number(option.value) && value !== option.value) {
                    option.disabled = true;
                }
            });
        });

        // console.log('reset select');
        // var sel = document.getElementById("selectContentId");
        // var opts = sel.options;
        // for (let opt, j = 0; opt = opts[j]; j++) {
        //     this.state.pois.forEach(e => {
        //         if (e.contentId === opt.value && value !== opt.value) {
        //             opt.disabled = true;
        //         }
        //     });

        //     if (value !== "" && opt.value === "") {
        //         opt.disabled = true;
        //     }

        //     if (value === "" && opt.value === "") {
        //         opt.disabled = false;
        //     }

        //     if (opt.value === value) {
        //         sel.selectedIndex = j;
        //         opt.disabled = false;
        //         if (document.getElementsByClassName("item-after")[0]) {
        //             document.getElementsByClassName("item-after")[0].innerHTML = opt.text
        //         }
        //     }
        // }
    }

    /**
     * Funzione che resetta il punto di interesse nel caso non fosse selezionato
     */
    resetPoI = () => {
        console.log('debug view', 'resetPoi')
        let temp_poi = this.state.pois.filter(x => { return x.contentId !== "" });
        if (this.state.newPoI) {
            temp_poi = temp_poi.filter(x => { return x.contentId !== this.state.selectedPoI.contentId });
        }

        if (temp_poi) {
            this.setState({ selectedPoI: null, pois: temp_poi });
        } else {
            this.setState({ selectedPoI: null });
        }

        if (this.state.error) {
            PkArDiscover.getModelViewKeyPoints(this.props.view.uniqueId)
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.setState({ pois: res.data, error: false });
                    }
                });
        }

        if (document.getElementsByClassName("link popup-close")[0]) {
            document.getElementsByClassName("link popup-close")[0].click();
        }
    }

    /**
     * Funzione che chiama l'api per eliminare un punto di interesse
     */
    deletePoI = () => {
        let dom_selected_poi = null;
        if (this.state.selectedPoI.contentId !== "") {
            dom_selected_poi = this.isHtmlElement(document.getElementById(this.state.selectedPoI.keypointUniqueId));
        }
        let temp_poi = this.state.pois.filter(poi => { return poi.contentId !== this.state.selectedPoI.contentId });

        PkArDiscover.deleteModelViewKeyPoint(this.props.view.uniqueId, this.state.selectedPoI.contentId)
            .then(res => {
                if (res.result.success) {
                    this.setState({
                        pois: temp_poi,
                        selectedPoI: null
                    });

                    if (dom_selected_poi) {
                        this.props.f7router.navigate('/discover-view-detail', { props: { modelData: this.props.modelData, view: this.props.view, image: this.props.image, layer: this.state.layer }, reloadAll: true });
                    }
                }
            });

        if (dom_selected_poi) {
            this.props.f7router.navigate('/discover-views', { props: { modelData: this.props.modelData }, reloadAll: true });
        }
    }

    /**
     * Funzione che chiama l'api per elimanare una view
     */
    deleteView = () => {
        this.showDialogPreloader();

        PkArDiscover.deleteModelView(this.props.view.uniqueId)
            .then((response) => {
                console.log('success deleting view')

                setTimeout(() => {
                    this.hideDialogPreloader();

                    this.props.f7router.navigate('/discover-views', { props: { modelData: this.props.modelData, deletedView: this.props.view.uniqueId }, reloadAll: true });
                }, 500)
            })
            .catch(() => {
                console.log('error deleting view')

                this.hideDialogPreloader();
            });
    }

    /**
     * Funzione che controlla se l'elemento passato è un html element
     * 
     * @param {object} o oggetto html
     * @returns true se l'oggetto è un html element o false se non lo è 
     */
    isHtmlElement = (o) => {
        return (
            typeof HTMLElement === "object" ? o instanceof HTMLElement :
                o && typeof o === "object" && o !== null && o.nodeType === 1 && typeof o.nodeName === "string"
        );
    }

    /**
     * Funzione che ritorna il valore della traslazione di un elemento
     * 
     * @param {object} element elemento html
     * @return oggetto con coordinate x e y della traslazione
     */
    getTranslateXY = (element) => {
        if (this.isHtmlElement(element)) {
            let style = window.getComputedStyle(element)
            let matrix = new DOMMatrixReadOnly(style.transform)
            return {
                x: matrix.m41,
                y: matrix.m42
            }
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    }

    /**
     * Funzione che seleziona o salva il movimento di un punto di interesse
     * 
     * @param {object} e oggetto del click sul punto di interesse
     * @param {object} data oggetto con i valori delle coordinate del punto di interesse
     */
    handleStop(e, data) {
        const translate = this.getTranslateXY(e.target.parentElement)
        let temp_poi = this.state.pois.filter(x => { return x.keypointUniqueId === e.target.parentElement.id })[0];
        let posX;
        let posY;
        if (temp_poi) {
            const tempPoiRadius = document.getElementById(temp_poi.keypointUniqueId) ? document.getElementById(temp_poi.keypointUniqueId).offsetWidth / 2 : 0;
            console.log('temp_poi', temp_poi);
            posX = temp_poi.posX * document.getElementById("pois-image").getBoundingClientRect().width / this.state.imageWidth;
            posY = temp_poi.posY * document.getElementById("pois-image").getBoundingClientRect().height / this.state.imageHeight;


            console.log('poi_data', document.getElementById("pois-image").getBoundingClientRect().width);
            console.log('poi_data', document.getElementById("pois-image").getBoundingClientRect().height);
            console.log('poi_data - data.x', data.x); //la posizione del click
            console.log('poi_data - posX', posX); //la posizione del poi
            console.log('poi_data - data.y', data.y);
            console.log('poi_data - posY', posY);
            console.log('poi_data - data.lastX', data.lastX);
            console.log('poi_data - this.state.lastX', this.state.lastX);
            console.log('poi_data - data.lastY', data.lastY);
            console.log('poi_data - this.state.lastY', this.state.lastY);


            // Gestione movimento o click del poi
            if (((data.x > posX - tempPoiRadius && data.x < posX + tempPoiRadius) &&
                (data.y > posY - tempPoiRadius && data.y < posY + tempPoiRadius)) ||
                (data.lastX === this.state.lastX && data.lastY === this.state.lastY)) {
                console.log('poi_clicked');
                this.changePopoverOptionsAvailable(e.target.dataset.contentid);
                this.setState({
                    selectedPoI: temp_poi,
                    contentSheetOpened: true
                });
                console.log('color picker custom', document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].children[1].children[0]);
                // document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].children[1].children[0].style.display = "none";
                document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].addEventListener("click", () => {
                    document.getElementsByClassName("colorpicker-custom")[0].children[0].children[1].children[1].children[0].click();
                });
            } else {
                console.log('poi_moved');
                this.setState({ movement: true });
                temp_poi.posX = translate.x;
                temp_poi.posY = translate.y;
                this.setState({ selectedPoI: temp_poi, lastX: data.lastX, lastY: data.lastY }, () => {
                    if (this.state.selectedPoI) {
                        this.savePoI(true);
                    }
                });
            }
        }
    }

    handleStart(e, data) {
        console.log('handleStart', e);
    }

    handleDrag(e, data) { }

    /**
     * Funzione che chiama l'api per salvare il layer
     */
    saveMask = () => {
        const resolveSaving = (error) => {
            if (error) {
                this.showToast(localeStrings.saving_mask_error);
            }
            this.hidePreloader();
        }

        this.showPreloader();

        let layerbase64 = "";
        let canvas = "";
        const canvasArray = document.querySelectorAll("canvas");

        if (canvasArray.length > 0) {
            canvas = canvasArray[1];
            if (canvas) {
                layerbase64 = canvas.toDataURL();
                setTimeout(() => {
                    let newCanvas = document.createElement("canvas");
                    let newCtx = newCanvas.getContext('2d');

                    newCanvas.width = this.state.imageWidth;
                    newCanvas.height = this.state.imageHeight;
                    let image = new Image()
                    image.onload = function () {
                        newCtx.drawImage(image, 0, 0, image.width, image.height,
                            0, 0, newCanvas.width, newCanvas.height);
                    }
                    image.src = layerbase64;

                    setTimeout(() => {
                        PkArDiscover.setModelViewMask(this.props.view.uniqueId, newCanvas.toDataURL().substr(22)).then((response) => {
                            if (response.result.code !== 200) {
                                resolveSaving(true);
                                PkLog.error(response);
                            }
                        }).catch((err) => {
                            resolveSaving(true);
                        }).finally(() => {
                            resolveSaving();
                        })
                    }, 500)
                }, 500);
            }
            else {
                resolveSaving(true);
            }
        }
        else {
            resolveSaving(true);
        }
    }

    /**
     * Funzione che disegna il layer
     */
    drawLayer = () => {

        const resolveDrawing = (error) => {
            if (error) {
                this.showToast(localeStrings.drawing_mask_error);
            }
            this.hidePreloader();
        }

        this.showPreloader();

        if (this.state.layer && this.state.layer !== "file not found") {
            setTimeout(() => {
                let canvas = "";
                // document.querySelectorAll("canvas").forEach((e) => {
                //     // if (e.style.zIndex === 11) {
                //     canvas = e;
                //     // }
                // });

                const canvasArray = document.querySelectorAll("canvas");

                if (canvasArray.length > 0) {
                    canvas = canvasArray[1];
                    if (canvas) {
                        let ctx = canvas.getContext('2d');
                        let image = new Image();
                        image.onload = function () {
                            ctx.drawImage(image, 0, 0, image.width, image.height,
                                0, 0, canvas.width, canvas.height);
                        }
                        image.src = this.state.layer;
                        resolveDrawing();
                    }
                    else {
                        resolveDrawing(true);
                    }
                }
                else {
                    resolveDrawing(false);
                }
            }, 300);
        }
        else {
            resolveDrawing(false);
        }
    }

    showDialogPreloader = () => {
        f7.dialog.preloader();
    }
    hideDialogPreloader = () => {
        f7.dialog.close();
    }

    showPreloader = () => {
        f7.preloader.show('black');
    }
    hidePreloader = () => {
        f7.preloader.hide();
    }
    showToast = (text) => {
        f7.toast.create({
            text: text,
            closeTimeout: 2000,
        }).open();
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.DISCOVER_VIEW_DETAIL);

        PkArDiscover.getModelViewKeyPoints(this.props.view.uniqueId)
            .then((res) => {
                if (res.data && res.data.length > 0) {
                    this.setState({ pois: res.data });
                }
            });

        PkArDiscover.getKeypointContentsData(this.props.modelData.model.contentId)
            .then((res) => {
                if (res.data && res.data.items.length > 0) {
                    this.setState({ popoverOptions: res.data.items });
                }
            });
    }
    componentDidUpdate = () => {
    }
    render() {
        let options = null;
        if (this.state.popoverOptions.length > 0) {
            let group = [];

            this.state.popoverOptions.forEach((popoverOption) => {
                if (!group[popoverOption.content.ctnView]) {
                    group[popoverOption.content.ctnView] = [];
                }

                group[popoverOption.content.ctnView].push({ popoverOption });
            });

            let popoverOptionsInCurrentView = [];

            options = this.state.contentGroup.map((contentTypology) => {
                return (
                    group[contentTypology.id] !== undefined ?
                        <optgroup key={contentTypology.id} label={contentTypology.name}>
                            {group[contentTypology.id].map((optionSubGroup) => {
                                popoverOptionsInCurrentView.push(optionSubGroup.popoverOption);
                                return (
                                    <option
                                        key={optionSubGroup.popoverOption.content.id}
                                        value={optionSubGroup.popoverOption.content.id}>
                                        {optionSubGroup.popoverOption.content.title}
                                    </option>
                                )
                            })}
                        </optgroup>
                        :
                        <></>
                )
            });
        }

        let pois = null;
        if (this.state.pois.length > 0) {
            pois = this.state.pois.map((e, i) => {
                let posX = 0;
                let posY = 0;
                if (document.getElementById("pois-image")) {
                    posX = e.posX * document.getElementById("pois-image").getBoundingClientRect().width / this.state.imageWidth;
                    posY = e.posY * document.getElementById("pois-image").getBoundingClientRect().height / this.state.imageHeight;
                }
                return (
                    <Draggable
                        key={"poi-" + i}
                        axis="both"
                        handle={".handle-" + i}
                        bounds="parent"
                        defaultPosition={{
                            x: posX,
                            y: posY
                        }}
                        onStart={(e, data) => { this.handleStart(e, data) }}
                        onDrag={(e, data) => { this.handleDrag(e, data) }}
                        onStop={(e, data) => { this.handleStop(e, data) }}>
                        <div
                            id={e.keypointUniqueId}
                            data-contentid={e.contentId}
                            style={{ position: "absolute", }}>
                            <div style={{ background: e.color }} className={"circle"} />
                            <img className={"handle-" + i} src={'./img/baseline_move_element_black_18dp.png'} alt="logo" style={{
                                width: '16px',
                                padding: '9px',
                                display: 'flex',
                                position: 'absolute',
                                top: '12px',
                                right: '-15px',
                            }} />
                        </div>
                    </Draggable>
                )
            });
        }

        const image = new Image();
        image.onload = () => {
            if (this.state.imageWidth !== image.width || this.state.imageHeight !== image.height)
                this.setState({ imageWidth: image.width, imageHeight: image.height });
        }
        image.src = this.props.image;

        return (
            <Page
                pageContent={false}
                hideToolbarOnScroll
                hideBarsOnScroll
                hideNavbarOnScroll>
                <Navbar>
                    <NavLeft backLink="Back" onBackClick={() => {
                        this.props.f7router.navigate('/discover-views', { props: { modelData: this.props.modelData }, reloadAll: true })
                    }} />
                    <NavTitle>{"View " + this.props.view.id}</NavTitle>
                    <NavRight>
                        <Link
                            iconOnly={true}
                            iconIos="f7:ellipsis_vertical"
                            iconAurora="f7:ellipsis_vertical"
                            iconMd="material:more_vert"
                            popoverOpen=".popover-delete-view-menu" />
                    </NavRight>
                </Navbar>
                <Toolbar tabbar position={'top'} >
                    <Link tabLink="#tab-edit-discover-views-poi" tabLinkActive
                        onClick={() => {
                            this.setState({ showMaskButtons: false })
                            if (this.sigCanvas) {
                                this.sigCanvas.clear();
                            }
                        }}>{localeStrings.view_tab_poi}</Link>
                    <Link tabLink="#tab-edit-discover-views-mask"
                        onClick={() => {
                            this.setState({ showMaskButtons: true })
                            document.addEventListener("mousedown", (e) => {
                                if (e.target.tagName === "CANVAS") {
                                    this.setState({ showMaskButtons: false })
                                }
                            });
                            document.addEventListener("mouseup", (e) => {
                                if (e.target.tagName === "CANVAS") {
                                    this.setState({ showMaskButtons: true })
                                }
                            });
                            document.addEventListener("touchstart", (e) => {
                                if (e.target.tagName === "CANVAS") {
                                    this.setState({ showMaskButtons: false })
                                }
                            });
                            document.addEventListener("touchend", (e) => {
                                if (e.target.tagName === "CANVAS") {
                                    this.setState({ showMaskButtons: true })
                                }
                            });
                            this.drawLayer();
                        }}>
                        {localeStrings.view_tab_mask}
                    </Link>
                </Toolbar>
                <PageContent>
                    <Block>
                        <Tabs animated >
                            <Tab id="tab-edit-discover-views-poi" tabActive>
                                {/* capire come centrare poi-container */}
                                <div className="poi-container" style={{ display: 'inline-block' }}>
                                    <div className="image-container">
                                        <img id="pois-image" src={this.props.image} alt="pois editor"
                                            style={{
                                                maxWidth: window.innerWidth / 1.1,
                                                maxHeight: window.innerHeight / 1.5,
                                                margin: "auto"
                                            }} />
                                        <div id="pois-container" className="bg-canvas"
                                            style={{
                                                position: "absolute",
                                                width: document.getElementById("pois-image") ? document.getElementById("pois-image").getBoundingClientRect().width : window.innerWidth / 1.1,
                                                height: document.getElementById("pois-image") ? document.getElementById("pois-image").getBoundingClientRect().height : window.innerHeight / 1.5
                                            }}
                                            onClick={(e) => {
                                                this.createPoI(e)
                                            }}>
                                            {pois}
                                        </div>
                                    </div>
                                </div>
                                {/** VIEW MODALE PER INSERIMENTO POI */}
                                <Sheet
                                    id="sheet-modal-poi"
                                    className="sheet-style"
                                    swipeToClose
                                    backdrop
                                    closeByBackdropClick
                                    opened={this.state.contentSheetOpened}
                                    onSheetClosed={() => {
                                        this.setState({ contentSheetOpened: false })
                                        this.resetPoI()
                                    }}>
                                    <PageContent>
                                        <BlockTitle large>{localeStrings.point_of_interest}</BlockTitle>
                                        <Block>
                                            <List>
                                                <ListItem
                                                    title={localeStrings.sheet_content_title}
                                                    className="padding-left-0 list-item-poi"
                                                    smartSelect
                                                    smartSelectParams={{
                                                        openIn: 'popup',
                                                        searchbar: true,
                                                        searchbarPlaceholder: localeStrings.sheet_search_placeholder,
                                                        closeOnSelect: true,
                                                        sheetBackdrop: true,
                                                    }}>

                                                    <select
                                                        id="selectContentId"
                                                        name="contentId"
                                                        onChange={(e) => {
                                                            let selectedPoI = this.state.selectedPoI;
                                                            selectedPoI.contentId = e.target.value;
                                                            this.setState({
                                                                prevContentId: this.state.selectedPoI.contentId,
                                                                selectedPoI: selectedPoI
                                                            })
                                                            this.savePoI(false)
                                                        }}>
                                                        <option key="" value="" >---</option>
                                                        {options}
                                                    </select>
                                                </ListItem>
                                                <ListInput
                                                    className="padding-left-0  colorpicker-custom"
                                                    type='colorpicker'
                                                    label={localeStrings.color}
                                                    inlineLabel
                                                    value={{
                                                        hex: this.state.selectedPoI ? this.state.selectedPoI.color : "#ff0000"
                                                    }}
                                                    onColorPickerChange={(e) => { this.setColorSelectedPoI(e) }}>
                                                    <Icon
                                                        icon="icon-custom"
                                                        slot="media"
                                                        style={{
                                                            backgroundColor: this.state.selectedPoI ? this.state.selectedPoI.color : "#ff0000"
                                                        }} />
                                                </ListInput>
                                                <li className="poi-option sheet-close"
                                                    style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <span className="color-red" onClick={this.deletePoI}>Delete</span>
                                                </li>
                                            </List>
                                        </Block>
                                    </PageContent>
                                </Sheet>
                            </Tab>
                            <Tab id="tab-edit-discover-views-mask">
                                <div className="mask-container">
                                    {/* <div className="image-container"> */}
                                    {/* <img id="canvas-image" src={this.props.image} alt="mask of the object"
                                            style={{
                                                position: "absolute",
                                                maxWidth: window.innerWidth / 1.1,
                                                maxHeight: window.innerHeight / 1.5,
                                            }} /> */}
                                    <CanvasDraw
                                        imgSrc={this.props.image}
                                        ref={(canvasDraw) => {
                                            this.saveableCanvas = canvasDraw
                                        }}
                                        lazyRadius={0}
                                        brushRadius={this.state.brushRadius}
                                        hideGrid={true}
                                        hideInterface={true}
                                        brushColor="#000"
                                        canvasWidth={document.getElementById("pois-image") ? document.getElementById("pois-image").getBoundingClientRect().width : window.innerWidth / 1.1}
                                        canvasHeight={document.getElementById("pois-image") ? document.getElementById("pois-image").getBoundingClientRect().height : window.innerHeight / 1.5}
                                    />
                                    {/* </div> */}
                                </div>
                            </Tab>
                        </Tabs>
                    </Block>

                    {this.state.showMaskButtons ?
                        <div id="mask-buttons">
                            <Block>
                                <Row>
                                    <Col>
                                        <Button
                                            text={localeStrings.mask_model_clear}
                                            bgColor='red'
                                            onClick={() => {
                                                this.saveableCanvas.clear()
                                                this.setState({ layer: null })
                                            }}
                                            fill />
                                    </Col>
                                    <Col>
                                        <Button
                                            text={localeStrings.mask_model_undo}
                                            bgColor='orange'
                                            onClick={() => {
                                                this.saveableCanvas.undo();
                                                this.drawLayer();
                                            }}
                                            fill />
                                    </Col>
                                    <Col>
                                        <Button
                                            text={localeStrings.mask_model_save}
                                            bgColor='green'
                                            onClick={() => {
                                                this.saveMask();
                                            }}
                                            fill />
                                    </Col>
                                </Row>
                            </Block>

                            <List simpleList>
                                <ListItem>
                                    <ListItemCell className="width-auto flex-shrink-0">
                                        <Icon
                                            ios="f7:largecircle_fill_circle"
                                            aurora="f7:largecircle_fill_circle"
                                            md="f7:largecircle_fill_circle" />
                                    </ListItemCell>
                                    <ListItemCell className="flex-shrink-3">
                                        <Range
                                            onRangeChange={(e) => {
                                                this.setState({
                                                    drawingRadius: 20 * e / 10,
                                                    brushRadius: e,
                                                })
                                            }}
                                            min={1}
                                            max={50}
                                            step={1}
                                            value={this.state.brushRadius}
                                            label={true} />
                                    </ListItemCell>
                                    <ListItemCell className="width-auto flex-shrink-0">
                                        <Icon
                                            ios="f7:circle_fill"
                                            aurora="f7:circle_fill"
                                            md="f7:circle_fill" />
                                    </ListItemCell>
                                </ListItem>
                            </List>

                            <div
                                id="drawing-radius"
                                className={"circle"}
                                style={{
                                    margin: 'auto',
                                    background: 'black',
                                    width: this.state.drawingRadius + 'px',
                                    height: this.state.drawingRadius + 'px',
                                }}></div>
                            {/*                             
                            <Toolbar tabbar position={'bottom'} style={{ background: "#fff", position: "absolute", bottom: 0, fontSize: "1.2rem" }}>
                                <Link>
                                    <Button
                                        color='red'
                                        onClick={() => {
                                            this.saveableCanvas.clear()
                                        }}
                                        fill>
                                        Clear
                                    </Button>
                                </Link>
                                <Link>
                                    <Button
                                        color='orange'
                                        onClick={() => {
                                            this.saveableCanvas.undo();
                                            this.drawLayer();
                                        }}
                                        fill>
                                        Undo
                                    </Button>
                                </Link>
                                <Link>
                                    <Button
                                        color='green'
                                        onClick={() => {
                                            this.saveMask();
                                        }}
                                        fill>
                                        Save
                                    </Button>
                                </Link>
                            </Toolbar> */}
                        </div>
                        : ""
                    }

                    {/* <div id="mask-buttons" className="toolbar toolbar-bottom" style={{ background: "#fff", display: "none", position: "absolute", bottom: 0, fontSize: "1.2rem" }}>
                        <div className="toolbar-inner" style={{ background: "#fff" }}>
                            <a className="link">
                                <div className="color-red" onClick={() => { this.saveableCanvas.clear() }}>Clear</div>
                            </a>
                            <a className="link">
                                <div className="color-orange" onClick={() => { this.saveableCanvas.undo(); this.drawLayer(); }}>Undo</div>
                            </a>
                            <a className="link">
                                <div className="color-green" onClick={() => { this.saveMask() }}>Save</div>
                            </a>
                        </div>
                    </div> */}
                    <Popover className='popover-delete-view-menu'>
                        <List>
                            <ListItem
                                className='popover-close'
                                onClick={() => this.deleteView()}
                                title={localeStrings.delete_view} />
                        </List>
                    </Popover>
                </PageContent>
            </Page >
        )
    }
}

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverViewDetail);
import { PkApiProducts } from "../api/products";
import { PkReduxActions } from "../redux/actions";
import { PkStore } from "../redux/store";
import { PkApiNet } from "../api/net";
import { Pk } from "../index";

export class PkApiAppCompany {
  static getAppCompany = () => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "get",
        url: Pk.getConfig().apiUrl + "/services/app-company",
      })
        .then(function (response) {
          if (response.result.success === true) {
            PkStore.get().dispatch(PkReduxActions.setAppCompany(response.data));
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
}

export default PkApiProducts;

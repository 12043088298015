
import React from 'react'

class ReactUploadImage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            file: null
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
    }
    onFormSubmit(e) {

        /*
        const formData = new FormData();
        formData.append('myImage',this.state.file);
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        axios.post("/upload",formData,config)
            .then((response) => {
                alert("The file is successfully uploaded");
            }).catch((error) => {
        });*/
    }
    onChange(e) {
        this.setState({ file: e.target.files[0] });
        this.props.onImagePicked(e.target.files[0]);
    }

    render() {
        return (
            <form onSubmit={this.onFormSubmit}>
                <input id='input_image' type="file" onChange={this.onChange} />
            </form>
            /*
            
            <div className="input">
                <input type="file" name="myImage" onChange={this.onChange} /></div>
            </form>*/
        )
    }
}

export default ReactUploadImage
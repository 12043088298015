import { PkApiNet } from "../api/net";
import { Pk } from "../index";
import { PkStore } from "../redux/store";
import { PkReduxActions } from "../redux/actions";
import { PkCommons } from "../commons";

export class PkApiAddressBook {
  static getAddressBook = () => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "get",
        url: Pk.getConfig().apiUrl + "/services/addressbook",
      })
        .then(function (response) {
          if (response.result.success === true) {
            PkStore.get().dispatch(
              PkReduxActions.setUserAdressBook(response.data)
            );
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static addToAddressBook = (contact) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("contactUniqueId", contact.UniqueId);

      PkApiNet.callApi({
        method: "post",
        data: bodyFormData,
        url: Pk.getConfig().apiUrl + "/services/addressbook",
      })
        .then(function (response) {
          if (response.result.success === true) {
            let addressbook =
              PkStore.getState().userAddressBook !== null
                ? [...PkStore.getState().userAddressBook]
                : [];
            let contactIndex = PkCommons.getElementIndexFromUniqueId(
              addressbook,
              contact.UniqueId
            );
            //se non c'è lo aggiungo
            if (contactIndex < 0) {
              addressbook = PkApiAddressBook.addContactToAddressBookArray(
                addressbook,
                contact
              );
              PkStore.get().dispatch(
                PkReduxActions.setUserAdressBook(addressbook)
              );
            }
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static addContactToAddressBookArray = (array, contact) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].Name > contact.Name) {
        array.splice(i, 0, contact);
        break;
      }
    }
    return array;
  };

  static removeFromAddressBook = (contactUniqueId) => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "delete",
        url: Pk.getConfig().apiUrl + "/services/addressbook/" + contactUniqueId,
      })
        .then(function (response) {
          if (response.result.success === true) {
            let addressbook =
              PkStore.getState().userAddressBook !== null
                ? [...PkStore.getState().userAddressBook]
                : [];
            let contactIndex = PkCommons.getElementIndexFromUniqueId(
              addressbook,
              contactUniqueId
            );
            if (contactIndex >= 0) {
              addressbook.splice(contactIndex, 1);
              PkStore.get().dispatch(
                PkReduxActions.setUserAdressBook(addressbook)
              );
            }
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getAddressBookInvitations = () => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "get",
        url: Pk.getConfig().apiUrl + "/services/addressbook-invitations",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static submitAddressBookInvitation = (email, name, roleUniqueId) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("email", email);
      bodyFormData.set("name", name);
      bodyFormData.set("roleUniqueId", roleUniqueId);

      PkApiNet.callApi({
        method: "post",
        data: bodyFormData,
        url: Pk.getConfig().apiUrl + "/services/addressbook-invitations",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static resendAddressBookInvitation = (uniqueId) => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "put",
        url:
          Pk.getConfig().apiUrl +
          "/services/addressbook-invitations/" +
          uniqueId,
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static deleteAddressBookInvitation = (uniqueId) => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "delete",
        url:
          Pk.getConfig().apiUrl +
          "/services/addressbook-invitations/" +
          uniqueId,
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
}

export default PkApiAddressBook;

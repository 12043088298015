
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    text: 'Logging out...',
    logout_button: "Logout",
    logout_alert_title: "Logout",
    logout_alert_text: "Do you want to logout?",
  },
  it: {
    text: 'Disconnessione...',
    logout_button: "Disconnetti",
    logout_alert_title: "Disconnetti",
    logout_alert_text: "Vuoi disconnetterti?",
  }
});
  
  export default localeStrings;
import React from 'react';
import { connect } from 'react-redux';
import { f7, Block, Card, CardHeader, Icon, Navbar, Page } from 'framework7-react';
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';
import Commons from '../../commons';
import localeStrings from './asset-recognition-local';
import FamilyJson from './familiy-json';
import { isIOS } from 'react-device-detect';
import * as actions from '../../redux/actions';
import AnalyticsRegister, { ANALYTICS_EVENT, ANALYTICS_SCREEN } from '../../analytics-register';
import AdvancedProductsJson from './advanced-products-json';

class FormDebugResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={localeStrings.results_page_title}
                        showNavRight={true}
                        showArrowAsNavRight={true}
                        searchBarEnabled={JSON.parse(process.env.REACT_APP_ENABLE_SEARCH_BAR) && this.state.enableSearchBar}
                        logo='./img/logo/logo.png'
                        customTitleClass='unique-product-title'
                        onBack={this.props.onBackClicked}
                        onSearch={this.onSearchBarSearchHandler}
                        showMenu={Commons.isSideMenuAllowed(f7)} />
                </Navbar>

                <div style={{ position: 'relative' }}>
                    {this.getTakenImage()}
                    {this.getContentList()}

                    {/* {this.getServerResponse()} */}

                    {this.getFamilyButton()}
                </div>

                {this.getSearchAllButton()}
            </Page>
        )
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.SCAN_RESULTS);
    }

    getTakenImage = () => {
        return (
            <Block style={{ position: 'relative', marginTop: 0 }}>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <img
                        alt='analyzed product'
                        src={`data:image/png;base64,${this.props.imageBase64}`}
                    // style={{
                    //     width: 'auto',
                    //     height: 'auto',
                    //     marginLeft: 'auto',
                    //     marginRight: 'auto',
                    //     display: 'block'
                    // }}
                    />
                </div>
            </Block>
        )
    }

    getContentList = () => {
        const contentList = this.props.contentData;

        if (contentList) {
            const ret = contentList.map((item, index) => {
                return (
                    <div onClick={() => {
                        console.log('content list button clicked', item)
                        this.onItemCardClicked(item);
                    }}>
                        <Card className='product-result-card' key={item.content ? item.content.id : Math.random()}>
                            <CardHeader style={{ justifyContent: 'flex-start' }} className="no-border">
                                {item.content ? <div>
                                    <img
                                        alt='person'
                                        src={item.content.imageUrl}
                                        width="80"
                                        height="80"
                                    />
                                </div> : ""}
                                <Block>{item.content ? item.content.title : item}</Block>
                            </CardHeader>
                        </Card>
                    </div>
                )
            });

            return (
                <Block>
                    {ret}
                </Block>
            )
        }
    }

    getServerResponse = () => {
        console.log(this.props.data);

        const classesList = this.props.data.data.list_results.classes;
        const probabilitiesList = this.props.data.data.list_results.probabilities;
        const classesFamiliyList = this.props.data.data.list_family_results.classes;
        const probabilitiesFamilyList = this.props.data.data.list_family_results.probabilities;

        const classes = classesList.map((item, index) => {
            return {
                class: item,
                probability: probabilitiesList[index]
            }
        });

        const families = classesFamiliyList.map((item, index) => {
            return {
                familiy: item,
                probability: probabilitiesFamilyList[index]
            }
        });


        const ret = classes.map((item, index) => {
            return (
                <Block>
                    <p>{item.class} - {item.probability} </p>
                </Block>
            )
        });

        return (
            <Card>
                {ret}
            </Card>
        )
    }

    getFamilyButton = () => {
        if (this.props.families && this.props.families.length > 0) {

            const familyJson = FamilyJson.getFamiliyJson();

            console.log('family', 'getFamiliyJson');
            console.log('family', this.props.menu);

            let productMenuItem = null;
            this.props.menu.forEach(menuItem => {
                if (menuItem.code === "/prodotti") {
                    productMenuItem = menuItem;
                }
            });

            let family = null;
            let familyFound = this.props.families[0].familiy;
            if (familyFound.toLowerCase().includes("advanced")) {
                familyFound = this.checkAdvancedFamilyCode();
            }
            if (productMenuItem) {
                productMenuItem.items.forEach(familyElement => {
                    if (familyElement.code === familyFound) {
                        family = familyElement;
                    }
                });
            }

            let imageSrc = null;
            if (family) {
                familyJson.forEach(jsonElement => {
                    if (jsonElement.id === family.id) {
                        imageSrc = jsonElement.imageSrc;
                    }
                });

                if (!imageSrc) {
                    imageSrc = './img/menu-icons/logo_default.png';
                }
            }

            if (family) {
                return (
                    <Block
                        style={{ marginBottom: '50px' }}>
                        <div onClick={() => {
                            const productMenuItem = this.getProductPageMenuItem();
                            if (productMenuItem != null) {
                                this.props.setSubMenuToOpen(family);

                                this.registerAnalyticsEvent(ANALYTICS_EVENT.OPEN_FAMILY_DETAIL, family);

                                if (document.getElementById(productMenuItem.title + '-' + productMenuItem.id)) {
                                    document.getElementById(productMenuItem.title + '-' + productMenuItem.id).click();
                                }
                                else {
                                    this.redirectToProductPage();
                                }
                            }

                            // this.onFamiliyCardClicked(family);
                        }}>
                            <Card key={"family-card" + family.id}>
                                <CardHeader style={{ justifyContent: 'flex-start' }} className="no-border">
                                    <div>
                                        <img
                                            alt='family'
                                            src={imageSrc}
                                            width="65"
                                            height="65"
                                        />
                                    </div>
                                    <Block>{family.title}</Block>
                                </CardHeader>
                            </Card>
                        </div>
                    </Block >
                )
            }
        }
    }

    checkAdvancedFamilyCode = () => {
        const contentList = this.props.contentData;
        let familyTag = null;

        if (contentList && contentList.length > 0) {
            const tagFound = contentList[0].content.tag;
            const advancedProductsJson = AdvancedProductsJson.getAdvancedProductsJson();
            advancedProductsJson.forEach(product => {
                if (product.codeUn === tagFound) {
                    familyTag = product.code;
                }
            });
        }

        return familyTag;
    }

    getSearchAllButton = () => {
        const productMenuItem = this.getProductPageMenuItem();

        return (
            <div
                className='search-all-products-block'
                style={{
                    bottom: (isIOS ? '28px' : 0)
                }}>
                <Block style={{
                    textAlign: 'center',
                    fontSize: 'medium',
                    margin: '25px 0px 23px'
                }}>
                    <div onClick={() => {
                        if (productMenuItem != null) {
                            if (document.getElementById(productMenuItem.title + '-' + productMenuItem.id)) {
                                document.getElementById(productMenuItem.title + '-' + productMenuItem.id).click();
                            }
                            else {
                                this.redirectToProductPage();
                            }
                        }
                    }}>
                        {localeStrings.search_in_the_catalog}
                        <Icon style={{ marginLeft: '3px' }}
                            slot="media"
                            ios="f7:search_circle"
                            aurora="f7:search_circle"
                            md="f7:search_circle" />
                    </div>
                </Block >
            </div>
        )
    }

    getProductPageMenuItem = () => {
        let menuItems = this.props.menu ? this.props.menu : [];
        let productMenuItem = null;
        menuItems.forEach(item => {
            if (item.code === process.env.REACT_APP_PRODUCTS_CODE_PAGE)
                productMenuItem = item;
        });

        return productMenuItem;
    }

    onItemCardClicked = (contentData) => {
        if (contentData && contentData.content) {
            this.registerAnalyticsEvent(ANALYTICS_EVENT.OPEN_RESULT_DETAIL, contentData.content);
            this.props.f7router.navigate(Commons.getContentDetailUrl(contentData.content), { props: { contentItem: contentData }, animate: true });
        }
        else {
            this.registerAnalyticsEvent(ANALYTICS_EVENT.OPEN_RESULT_DETAIL, { content: contentData });
            this.showToastBottom(localeStrings.code_not_inserted);
        }
    }

    // onFamiliyCardClicked = (family) => {
    //     this.registerAnalyticsEvent(ANALYTICS_EVENT.OPEN_FAMILY_DETAIL, family);

    //     const path = Commons.getMenuItemUrl(family)
    //     this.props.f7router.navigate(path, { props: { onBackClicked: this.onReturningFromFamilies } });
    // }

    onReturningFromFamilies = () => {
        // this.props.f7router.back();
        // if (!this.props.contentData) {
        //     console.log('onReturningFromFamilies');
        // }

        const scanPage = {
            title: "SCAN",
            code: "/asset-recognition",
            descr: "",
            imageUrl: null,
            attchmUrl: null,
            items: [],
            id: 18806,
            ctnType: 1,
            ctnView: 1,
            catIndex: 1,
        }

        const path = Commons.getMenuItemUrl(scanPage)
        this.props.f7router.navigate(path, { reloadAll: true });
    }

    showToastBottom = (text) => {
        this.toastBottom = f7.toast.create({
            text: text,
            closeTimeout: 2000,
        });
        this.toastBottom.open();
    }

    redirectToProductPage = () => {
        this.registerAnalyticsEvent(ANALYTICS_EVENT.OPEN_CATALOG_LIST, null);

        let menuItems = this.props.menu ? this.props.menu : [];
        let productMenuPath = null;
        menuItems.forEach(item => {
            if (item.code === process.env.REACT_APP_PRODUCTS_CODE_PAGE)
                productMenuPath = Commons.getMenuItemUrl(item)
        });

        this.props.f7router.navigate(productMenuPath, { reloadAll: true });
    }

    registerAnalyticsEvent = (eventName, params) => {
        AnalyticsRegister.logEvent(eventName, params);
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setSubMenuToOpen: (subMenu) => dispatch(actions.setSubMenuToOpen(subMenu)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(FormDebugResults);
import React from 'react';
import { Page, Block, Col, Preloader } from 'framework7-react';
import localeStrings from './logout-loader-local';


const LogoutLoader = () => (
    <Page pageContent={false} colorTheme="custom">
        <Block className="row demo-preloaders align-items-stretch text-align-center spinner">
            <Col>
                <Preloader size={42}></Preloader>
                <p>{localeStrings.text}</p>
            </Col>
        </Block>

    </Page>
)

export default LogoutLoader;
import { PkApiNet } from "../api/net";
import { Pk } from "../index";
import { PkStorage } from "../storage";
import { PkApiAuth } from "../api/auth";
import { PkStore } from "../redux/store";
import { PkLog } from "../log";

export class PkApiContacts {
  static getContactPublicKey = (contactUniqueId) => {
    if (
      PkStorage.getProfile() &&
      contactUniqueId === PkStorage.getProfile().UniqueId
    ) {
      return PkStorage.getProfile().PublicKey;
    }

    let addressBook = PkStore.getState().userAddressBook;
    if (addressBook) {
      for (let i = 0; i < addressBook.length; i++) {
        if (addressBook[i].UniqueId === contactUniqueId) {
          return addressBook[i].PublicKey;
        }
      }
    }
    return null;
  };

  static getContactsPublicKeys = (contactsUniqueId) => {
    if (!contactsUniqueId) {
      return null;
    }
    let publicKeys = [];
    contactsUniqueId.forEach((contactUniqueId) => {
      let publicKey = PkApiContacts.getContactPublicKey(contactUniqueId);
      if (publicKey) {
        publicKeys.push(
          PkApiContacts.getContactIdentities(contactUniqueId, publicKey)
        );
      }
    });
    return publicKeys;
  };

  static getContactIdentities = (uniqueId, publicKey) => {
    return { UniqueId: uniqueId, PublicKey: publicKey };
  };

  static updateAvailability = (availability) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("availability", availability);

      PkApiNet.callApi({
        method: "put",
        data: bodyFormData,
        url: Pk.getConfig().apiUrl + "/services/contacts",
      })
        .then(function (response) {
          if (response.result.success === true) {
            PkStorage.setProfile(response.data);
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static sessionId = null;

  static startOnlineSession = () => {
    if (PkApiContacts.sessionId == null) {
      PkApiContacts.sessionId = setInterval(PkApiContacts.sendPing, 30000);
    }
  };

  static sendPing = () => {
    if (PkApiAuth.isAuthenticated()) {
      let profile = PkStorage.getProfile();
      if (profile.Availability === AVAILABILITY.ONLINE) {
        PkApiNet.callApi({
          method: "get",
          url: Pk.getConfig().apiUrl + "/services/contacts?ping",
        }).catch(function (response) {
          PkLog.log("ping response error");
        });
      }
    }
  };

  static doLogout = () => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "delete",
        url: Pk.getConfig().apiUrl + "/services/contacts",
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getCompanies = () => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "get",
        url: Pk.getConfig().apiUrl + "/services/companies",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static isAssistedContact = (contact) => {
    return (
      contact && contact.Role && contact.Role.Code === CONTACT_ROLE.ASSISTED
    );
  };

  static emailExists = (email) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      bodyFormData.set("email", email);

      PkApiNet.callApi({
        method: "post",
        url: Pk.getConfig().apiUrl + "/services/contacts?checkemail",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
}

export const CONTACT_TYPE = {
  USER: 1,
  GROUP: 2,
};

export const CONTACT_ROLE = {
  OPERATOR: "OPERATOR",
  ASSISTED: "ASSISTED",
  ADMIN: "ADMIN",
};

export const AVAILABILITY = {
  ONLINE: 2,
  OFFLINE: 1,
};

export default PkApiContacts;

import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        title: "Tutorial",
        tutorial_toggle_label: "Do not show again",
        tutorial_button_label: "Start",
        tutorial_1: "Point the camera towards a flat surface and move the device around",
        tutorial_2: "Confirm the placement of the product",
        tutorial_3: "Interact with the product and its added content",
    },
    it: {
        title: "Tutorial",
        tutorial_toggle_label: "Non mostrare più",
        tutorial_button_label: "Avvia",
        tutorial_1: "Inquadra una superficie piana e muovi il dispositivo in modo circolare",
        tutorial_2: "Conferma la posizione del prodotto",
        tutorial_3: "Interagisci con il prodotto e i suoi contenuti aggiuntivi",
    }
});

export default localeStrings;
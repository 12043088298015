import Pk from '../index';
import PkApiNet from '../api/net';
import PkStorage from "../storage";


export class PkArViewInvolve {

    static getSceneMedia = (sceneId, subSceneId = null) => {
        const company = PkStorage.getLoginCompany();
        const customerCode = company ? company.Code : null;

        return new Promise((resolve, reject) => {

            PkApiNet.callApi({
                method: 'get',
                url: `${Pk.getConfig().apiUrl}/api/ar-scenes/${sceneId}/media/`,
                params: {
                    ...(customerCode && {customerCode}),
                    ...(subSceneId && {subScene: subSceneId})
                },
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static getScenes = () => {
        const company = PkStorage.getLoginCompany();
        const customerCode = company ? company.Code : null;

        return new Promise((resolve, reject) => {

            PkApiNet.callApi({
                method: 'get',
                url: `${Pk.getConfig().apiUrl}/api/ar-scenes/`,
                params: {
                    ...(customerCode && {customerCode})
                },
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

}

export default PkArViewInvolve;

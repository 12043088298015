import React from 'react';
import { connect } from 'react-redux';
import { Block, Page, Link, Panel, View, Row, Col, ListItem, List } from 'framework7-react';
import Commons from '../commons'
// import Lottie from "lottie-react";
// import animationData from '../assets/lottie/cube_animation.json'
import { PkLog } from '../pikkart-cms/log';
import IconHelper from '../helpers/icon-helper';

class Menu extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      infoContentsData: null,
      isStopped: false, isPaused: false
    };
  }

  render() {
    let menuItems = this.props.menu ? this.props.menu : [];
    let path = process.env.REACT_APP_DEFAULT_CODE_PAGE_NO_CONNECTION;
    menuItems.forEach(item => {
      if (item.code === process.env.REACT_APP_DEFAULT_CODE_PAGE)
        path = Commons.getMenuItemUrl(item)
    });

    return (
      <Panel left cover resizable className="menu-panel">
        <View>
          <Page pageContent={false} style={{ background: process.env.REACT_APP_MENU_TOP_COLOR, display: 'flex', flexFlow: 'column' }}>
            <Block textAlign="center" style={{ margin: '0', padding: '0', height: '163px' }}>
              <Link panelClose style={{ width: '100%' }} href={path} view={'.view-main'}>
                <Row style={{ width: '100%' }}>
                  <Col style={{ textAlign: 'left' }}>
                    <img src={'./img/logo/logo.png'} alt="logo" width={process.env.REACT_APP_MENU_LOGO_SIZE} style={{ margin: 'auto', marginTop: '23px', marginBottom: '20px', display: 'flex' }} />
                  </Col>
                </Row>
              </Link>
            </Block>
            <Block style={{ background: process.env.REACT_APP_MENU_VOICES_LIST_COLOR, margin: 0 }}>

              {this.getMenuItems()}
            </Block>

            <Row style={{ background: process.env.REACT_APP_MENU_BOTTOM_COLOR, width: '100%', flexGrow: '1' }}>
              <Col style={{ textAlign: 'end', position: 'absolute', bottom: '0' }}>
                {process.env.REACT_APP_CREDITS_PANEL_LOGO ?
                  <img src={'./img/logo/' + process.env.REACT_APP_CREDITS_PANEL_LOGO} alt="logo_pikkart" width="75" style={{ position: 'absolute', bottom: '0', right: '0', margin: '16px' }} />
                  : ""}
              </Col>
            </Row>
          </Page>
        </View>
      </Panel>
    )
  }

  getMenuItems = () => {

    let menuItems = this.props.menu ? this.props.menu : [];

    const ret = menuItems.map((item, index) => {

      /*
            <div>
              <Lottie autoplay={true} loop={false} onAnimationEnd={(e) => { this.animationEnded(e) }} animationData={animationData} />;
            </div>
      */

      let icon = JSON.parse(process.env.REACT_APP_USE_ICONS_ON_MENU_PANEL) ? IconHelper.getMenuIcon(item.code) : "";

      return (
        <ListItem
          className='menu-list-item'
          style={{
            background: process.env.REACT_APP_MENU_VOICES_LIST_COLOR,
            color: process.env.REACT_APP_VOICES_TEXT_COLOR
          }}
          key={'menu-item' + index}
          view={'.view-main'}
          panelClose
          link={Commons.getMenuItemUrl(item)}
        >
          {icon}
          {item.title}
        </ListItem>);
    });

    return <List style={{ margin: 0 }}>{ret}</List>;
  }

  animationEnded = (e) => {
    PkLog.log('animation ended', e)
  }

  componentDidMount = () => {
    console.log('menu componentdidmount');
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    menu: state.app.menu
  };
};

const mapDispatchToProps = dispatch => {
  return {

  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
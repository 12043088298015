// camera constants
const USER = 'user';
const ENVIRONMENT = 'environment';

export const SUPPORTED_FACING_MODES = [USER, ENVIRONMENT];

export const FACING_MODES = {
  'USER': USER,
  'ENVIRONMENT': ENVIRONMENT
};

// Image constants
const PNG = 'png';
const JPG = 'jpg';

export const SUPPORTED_IMAGE_TYPES = [JPG, PNG];

export const IMAGE_TYPES = {
  'PNG': PNG,
  'JPG': JPG
};

export const FORMAT_TYPES = {
  [JPG]: 'image/jpeg',
  [PNG]: 'image/png'
};

export const MINIMUM_CONSTRAINTS = {
  width: { min: 640, ideal: 3840 },
  height: { min: 480, ideal: 2160 }
};

export const VIDEO_ADVANCED_CONSTRANTS_FACING_MODE_USER = [
  { 'width': { 'min': 1056 }, 'ideal': { 'facingMode': USER } },
  { 'width': { 'min': 1280 }, 'ideal': { 'facingMode': USER } },
  { 'width': { 'min': 1920 }, 'ideal': { 'facingMode': USER } },
  { 'width': { 'min': 3264 }, 'ideal': { 'facingMode': USER } },
  { 'width': { 'min': 3840 }, 'ideal': { 'facingMode': USER } }
];

export const VIDEO_ADVANCED_CONSTRANTS_FACING_MODE_ENVIRONMENT = [
  { 'width': { 'min': 1056 }, 'ideal': { 'facingMode': ENVIRONMENT } },
  { 'width': { 'min': 1280 }, 'ideal': { 'facingMode': ENVIRONMENT } },
  { 'width': { 'min': 1920 }, 'ideal': { 'facingMode': ENVIRONMENT } },
  { 'width': { 'min': 3264 }, 'ideal': { 'facingMode': ENVIRONMENT } },
  { 'width': { 'min': 3840 }, 'ideal': { 'facingMode': ENVIRONMENT } }
];
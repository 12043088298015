
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    title: "oops!",
    text: "Something went wrong...",
    button: "Reload",
  },
  it: {
    title: "Accipicchia...!",
    text: "Qualcosa non ha funzionato correttamente...",
    button: "Riavvia",
  }
});
  
  export default localeStrings;
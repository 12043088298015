import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        title: "Settings",
        who: "Who are you?",
        tourist: "Tourist",
        citizen: "Citizen",
        birth_title: "When were you born?",
        birth_placeholder: "Your birth date",
        notifications_title: "Do you want to receive notifications?",
        notifications_label: "Activate notifications",
        confirm_data: "Do you want to confirm the data entered?"
    },
    it: {
        title: "Profilo",
        who: "Chi sei?",
        tourist: "Turista",
        citizen: "Cittadino",
        birth_title: "Quando sei nato?",
        birth_placeholder: "La tua data di nascita",
        notifications_title: "Vuoi ricevere comunicazioni?",
        notifications_label: "Attiva le notifiche",
        confirm_data: "Vuoi confermare il dato appena inserito?"
    }
});

export default localeStrings;
import Pk from './index';
import PkApiNet from './api/net';

export class PkContents {

    static getContentsMenu = () => {
        return new Promise((resolve, reject) => {

            PkApiNet.callApi({
                method: 'get',
                url: Pk.getConfig().apiUrl + '/api/infocontents?menu&l=' + Pk.getLocale(),
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    };

    static getInfoContentData = (contentId) => {
        return new Promise((resolve, reject) => {

            PkApiNet.callApi({
                method: 'get',
                url: Pk.getConfig().apiUrl + '/api/infocontents/' + contentId + '?l=' + Pk.getLocale(),
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    };

    static getInfoContentsDataPage = (filters, url) => {
        return new Promise((resolve, reject) => {

            let queryParams = [];
            if (!filters) {
                filters = {
                    page: 1,
                    pageSize: 10
                };
            }
            if (!filters.page) {
                filters.page = 1;
            }
            if (!filters.pageSize) {
                filters.pageSize = 10;
            }
            if (!filters.ac) {
                filters.ac = 1;
            }


            if (filters.nearFilters) queryParams.push('near');
            if (filters.page) queryParams.push('page=' + filters.page);
            if (filters.pageSize) queryParams.push('pageSize=' + filters.pageSize);
            if (filters.v) queryParams.push('v=' + filters.v);
            if (filters.query) queryParams.push('query=' + filters.query);
            if (filters.date) queryParams.push('date=' + filters.date);
            if (filters.c1) queryParams.push('c1=' + filters.c1);
            if (filters.c2) queryParams.push('c2=' + filters.c2);
            if (filters.c3) queryParams.push('c3=' + filters.c3);
            if (filters.groupId) queryParams.push('g=' + filters.groupId);
            if (filters.rel) queryParams.push('rel=' + filters.rel);
            if (filters.owCode) queryParams.push('owCode=' + filters.owCode);
            if (filters.ac) queryParams.push('ac=' + filters.ac);
            queryParams.push('l=' + Pk.getLocale());

            PkApiNet.callApi({
                method: filters.nearFilters ? 'post' : 'get',
                url: (url ? url : Pk.getConfig().apiUrl + '/api/infocontents') + '?' + queryParams.join('&'),
                data: filters.nearFilters ? filters.nearFilters : null,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    };

    static getArScenesContentsDataPage = (filters, url) => {
        return new Promise((resolve, reject) => {

            let queryParams = [];
            if (!filters) {
                filters = {
                    page: 1,
                    pageSize: 10
                };
            }
            if (!filters.page) {
                filters.page = 1;
            }
            if (!filters.pageSize) {
                filters.pageSize = 10;
            }
            if (!filters.ac) {
                filters.ac = 1;
            }


            if (filters.nearFilters) queryParams.push('near');
            if (filters.page) queryParams.push('page=' + filters.page);
            if (filters.pageSize) queryParams.push('pageSize=' + filters.pageSize);
            if (filters.v) queryParams.push('v=' + filters.v);
            if (filters.query) queryParams.push('query=' + filters.query);
            if (filters.date) queryParams.push('date=' + filters.date);
            if (filters.c1) queryParams.push('c1=' + filters.c1);
            if (filters.c2) queryParams.push('c2=' + filters.c2);
            if (filters.c3) queryParams.push('c3=' + filters.c3);
            if (filters.groupId) queryParams.push('g=' + filters.groupId);
            if (filters.rel) queryParams.push('rel=' + filters.rel);
            if (filters.owCode) queryParams.push('owCode=' + filters.owCode);
            if (filters.ac) queryParams.push('ac=' + filters.ac);
            queryParams.push('l=' + Pk.getLocale());



            PkApiNet.callApi({
                method: filters.nearFilters ? 'post' : 'get',
                url: (url ? url : Pk.getConfig().apiUrl + '/api/scenes') + '?' + queryParams.join('&'),
                data: filters.nearFilters ? filters.nearFilters : null,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    };

    static getArSceneContentData = (contentId) => {
        return new Promise((resolve, reject) => {

            PkApiNet.callApi({
                method: 'get',
                url: Pk.getConfig().apiUrl + '/api/scenes/' + contentId + '?l=' + Pk.getLocale(),
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static _getInfoContentsFilters = () => {
        return {
            nearFilters: null, // {userId, radius, location: {lat, lng, alt}, contentsView}
            page: 0,
            pageSize: 0,
            v: 0, //Enumetarot with: 1 - InfoContent, 3 - Place, 5 - BusinessCard, 6 - Company, 7 - DiscoverModel, 8 - Markerless
            query: null, //string filter
            date: 'none', //Enumerator with "none", "beforenow" and "afternow" values
            c1: 0,
            c2: 0,
            c3: 0,
            groupId: 0
        };
    }

    static getTriggersList = (triggerSourceType) => {
        return new Promise((resolve, reject) => {

            PkApiNet.callApi({
                method: 'get',
                url: Pk.getConfig().apiUrl + '/api/triggers?sType=' + triggerSourceType,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static getTrigger = (sId, triggerSourceType) => {
        return new Promise((resolve, reject) => {

            PkApiNet.callApi({
                method: 'get',
                url: Pk.getConfig().apiUrl + '/api/triggers?sid=' + sId + '&sType=' + triggerSourceType,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static deleteInfoContent = (contentId) => {
        return new Promise((resolve, reject) => {
            PkApiNet.callApi({
                method: 'delete',
                url: Pk.getConfig().apiUrl + '/api/infocontents/' + contentId,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static addInfoContent = (infoContent) => {
        return new Promise((resolve, reject) => {
            let queryParams = [];
            queryParams.push('l=' + Pk.getLocale());
            PkApiNet.callApi({
                method: 'post',
                data: infoContent,
                url: Pk.getConfig().apiUrl + '/api/infocontents?' + queryParams.join('&'),
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static setInfoContent = (contentId, infoContent) => {
        return new Promise((resolve, reject) => {
            PkApiNet.callApi({
                method: 'post',
                data: infoContent,
                url: Pk.getConfig().apiUrl + '/api/infocontents/' + contentId,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }

    static getProductFinderDataPage = (filters, url) => {
        return new Promise((resolve, reject) => {

            let queryParams = [];
            if (!filters) {
                filters = {
                    page: 1,
                    pageSize: 10
                };
            }
            if (!filters.page) {
                filters.page = 1;
            }
            if (!filters.pageSize) {
                filters.pageSize = 10;
            }
            if (!filters.ac) {
                filters.ac = 1;
            }


            if (filters.nearFilters) queryParams.push('near');
            if (filters.page) queryParams.push('page=' + filters.page);
            if (filters.pageSize) queryParams.push('pageSize=' + filters.pageSize);
            if (filters.v) queryParams.push('v=' + filters.v);
            if (filters.query) queryParams.push('query=' + filters.query);
            if (filters.date) queryParams.push('date=' + filters.date);
            if (filters.c1) queryParams.push('c1=' + filters.c1);
            if (filters.c2) queryParams.push('c2=' + filters.c2);
            if (filters.c3) queryParams.push('c3=' + filters.c3);
            if (filters.groupId) queryParams.push('g=' + filters.groupId);
            if (filters.rel) queryParams.push('rel=' + filters.rel);
            if (filters.owCode) queryParams.push('owCode=' + filters.owCode);
            if (filters.ac) queryParams.push('ac=' + filters.ac);
            if (filters.tags) queryParams.push('tags=' + filters.tags);
            queryParams.push('l=' + Pk.getLocale());

            PkApiNet.callApi({
                method: filters.nearFilters ? 'post' : 'get',
                url: (url ? url : Pk.getConfig().apiUrl + '/api/infocontents') + '?' + queryParams.join('&'),
                data: filters.nearFilters ? filters.nearFilters : null,
                config: {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    }
                }
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    };
}

export const CONTENT_VIEW = {
    INFOCONTENT: 1,
    PLACE: 3,
    BUSINESS_CARDS: 5,
    COMPANY: 6,
    DISCOVER_MODEL: 7,
    MARKERLESS: 8,
    DISCOVER_KEYPOINT: 9,
    ITINERARY: 10,
    AR_SCENE: 11
};

export const TRIGGER_SOURCE_TYPE = {
    BEACONS_SOURCE_TYPE: 1
};

export default PkContents;

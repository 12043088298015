
import LocalizedStrings from 'react-localization';

const appLocaleStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    no_contents: "No contents found",
    no_connection_message: "Please check your network connection and reload the app"
  },
  it: {
    no_contents: "Nessun contenuto trovato",
    no_connection_message: "Per favore controlla la connessione a internet e riavvia l'app"
  }
});
  
  export default appLocaleStrings;
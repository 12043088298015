export class ImageUtils {

	/** This function return a cropped image from the top left corner of input image
	 * @param {*} image Input image 
	 * @param {*} height Height requested
	 * @param {*} width Width's requested
	 * @returns 
	 */
	static cropImage = (image, startingX, height, width) => {
		let rect = new window.cv.Rect(startingX, 0, width, height);
		return image.roi(rect);
	}

	/** This function return the max center squared image contained inside an image
	* @param 	{cvMat} 	image 	Input image 
	* @param 	{int}		height 	Height's image	
	* @param 	{int}		width 	Width's image	
	* @return 	{cvMat}		Image Squared
	*/
	static cropSquaredImage = (image, height, width) => {
		let padding = 0;
		let rect = new window.cv.Rect(0, 0, width, height);
		// Case
		if (height > width) {
			console.log("Height more than width");
			padding = Math.floor((height - width) / 2);
			rect.y = padding;
			rect.height = width;
		} else if (height < width) {
			console.log("Width more than height");
			padding = Math.floor((width - height) / 2);
			rect.x = padding;
			rect.width = height;
		} else { // Image already squared
			console.log("Squared");
			// Nothing to do
		}
		// Crop image
		return image.roi(rect);
	}
	/** This function return a resized image of input image
	* @param 	{cvMat} 	image 		Input image 
	* @param 	{int}		new_height 	New image height
	* @param 	{int}		new_width 	New image width
	* @param 	{cv}		type		Type of resize
	* @return 	{cv::InterpolationFlags}	Image Resized
	*/
	static resizeImage = (image, new_height, new_width, type = window.cv.INTER_AREA) => {
		let dst = new window.cv.Mat();
		let dsize = new window.cv.Size(new_width, new_height);
		// You can try more different parameters
		window.cv.resize(image, dst, dsize, 0, 0, type);
		return dst;
	}
	static calculateCropsUsingFormat = (in_format, out_format) => {
		// Check input format
		if (in_format >= out_format) {
			let div = in_format / out_format;
			return [true, div];
		}
		return [false, 0];
	}
	static cropSquaredImageUsingFormat = (image, n_crop_h, n_crop_w) => {
		let curr_height = image.rows;
		let curr_width = image.cols;
		let list_crops = [];
		n_crop_h = parseInt(n_crop_h);
		n_crop_w = parseInt(n_crop_w);
		// Calculate dimension of crop
		let sq_height = parseInt(curr_height / n_crop_h);
		let sq_width = parseInt(curr_width / n_crop_w);
		// Extract squared dimension from Rect
		let sq_dim = Math.min(sq_height, sq_width);
		if (sq_height > sq_width) {
			n_crop_h *= parseInt(sq_height / sq_width)
		} else if (sq_height < sq_width) {
			n_crop_w *= parseInt(sq_width / sq_height);
		}
		// Iterate over crops
		for (let idx_h = 0; idx_h < n_crop_h; idx_h++) {
			for (let idx_w = 0; idx_w < n_crop_w; idx_w++) {
				// Create Crop rect
				let rect = new window.cv.Rect(idx_w * sq_dim, idx_h * sq_dim, sq_dim, sq_dim);
				// Crop image
				list_crops.push(image.roi(rect));
			}
		}
		return list_crops;
	}
}
export default ImageUtils;
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        take_picture: 'Take Picture',
        accordion_content_base_informations: 'Base informations',
        accordion_content_title: 'Title',
        accordion_content_description: 'Description',
        accordion_content_image: 'Image',
        accordion_content_button_camera: 'Camera',
        accordion_content_button_gallery: 'Gallery',
        accordion_content_button_remove: 'Remove',
        accordion_content_button_delete: 'Delete',
        accordion_content_button_save: 'Save',
        accordion_content_date_label: 'Date',
        accordion_content_date_placeholder: 'Enter date',
        accordion_content_date_url: 'URL',
        accordion_content_contacts: 'Contacts',
        accordion_content_email: 'E-mail',
        accordion_content_phone: 'Phone',
        accordion_content_fax: 'Fax',
        accordion_content_attachments: 'Attachments',
        accordion_content_pdf: 'PDF',
        accordion_content_file_name: 'File name',
        accordion_content_media: 'Media',
        accordion_content_youtube_url: 'Youtube url',
        accordion_content_youtube_description: 'Description',
        accordion_content_youtube_placeholder_description: 'Youtube description',
        accordion_content_audio: 'Audio (mp3)',
        accordion_content_social: 'Social',
        accordion_content_share_url: 'Share Url',
        accordion_content_pinterest_url: 'Pinterest',
        accordion_content_twitter_url: 'Twitter',
        accordion_content_facebook_url: 'Facebook',
        accordion_content_linkedin_url: 'Linkedin',
        accordion_content_instagram_url: 'Instagram',
        accordion_content_skype_url: 'Skype',
        accordion_content_pinterest_placeholder_url: 'Pinterest Url',
        accordion_content_twitter_placeholder_url: 'Twitter Url',
        accordion_content_facebook_placeholder_url: 'Facebook Url',
        accordion_content_linkedin_placeholder_url: 'Linkedin Url',
        accordion_content_instagram_placeholder_url: 'Instagram Url',
        accordion_content_skype_placeholder_url: 'Skype Url',
        accordion_content_ecommerce: 'E-commerce',
        accordion_content_price: 'Price',
        accordion_content_ecommerce_url: 'Url',
        accordion_content_ecommerce_placeholder_url: 'Url E-commerce',

    },
    it: {
        take_picture: 'Scatta Foto',
        accordion_content_base_informations: 'Informazioni di base',
        accordion_content_title: 'Titolo',
        accordion_content_description: 'Descrizione',
        accordion_content_image: 'Immagine',
        accordion_content_button_camera: 'Camera',
        accordion_content_button_gallery: 'Galleria',
        accordion_content_button_remove: 'Rimuovi',
        accordion_content_button_delete: 'Elimina',
        accordion_content_button_save: 'Salva',
        accordion_content_date_label: 'Data',
        accordion_content_date_placeholder: 'Inserisci la data',
        accordion_content_date_url: 'URL',
        accordion_content_contacts: 'Contatti',
        accordion_content_phone: 'Telefono',
        accordion_content_fax: 'Fax',
        accordion_content_attachments: 'Allegati',
        accordion_content_pdf: 'PDF',
        accordion_content_file_name: 'Nome del file',
        accordion_content_media: 'Media',
        accordion_content_youtube_url: 'Url youtube',
        accordion_content_youtube_description: 'Descrizione',
        accordion_content_youtube_placeholder_description: 'Descrizione youtube',
        accordion_content_audio: 'Audio (mp3)',
        accordion_content_social: 'Social',
        accordion_content_share_url: 'Url Generico',
        accordion_content_pinterest_url: 'Pinterest',
        accordion_content_twitter_url: 'Twitter',
        accordion_content_facebook_url: 'Facebook',
        accordion_content_linkedin_url: 'Linkedin',
        accordion_content_instagram_url: 'Instagram',
        accordion_content_skype_url: 'Skype',
        accordion_content_pinterest_placeholder_url: 'Url Pinterest',
        accordion_content_twitter_placeholder_url: 'Url Twitter',
        accordion_content_facebook_placeholder_url: 'Url Facebook',
        accordion_content_linkedin_placeholder_url: 'Url Linkedin',
        accordion_content_instagram_placeholder_url: 'Url Instagram',
        accordion_content_skype_placeholder_url: 'Url Skype',
        accordion_content_ecommerce: 'E-commerce',
        accordion_content_price: 'Prezzo',
        accordion_content_ecommerce_url: 'Url',
        accordion_content_ecommerce_placeholder_url: 'Url E-commerce',
    }
});

export default localeStrings;
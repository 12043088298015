
import PkApiNet from '../api/net';

export class PkArDeep {

    static processDeepModel = (params) => {
        return new Promise((resolve, reject) => {
            PkApiNet.callApi({
                method: 'post',
                data: params,
                url: process.env.REACT_APP_PRODUCT_FINDER_URL,
                // url: Pk.getConfig().apiUrl + '/api/deep/predict/' + modelName,
            })
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (errorMessage) {
                    reject(new Error(errorMessage));
                });
        });
    }
}

export default PkArDeep;
import React, {useEffect} from 'react';
import styles from './ar-web.module.css';

function ArWebIntro(props) {
    const [isIntroVisible, setIsIntroVisible] = React.useState(true);
    const [isIntroFading, setIsIntroFading] = React.useState(false);

    useEffect(() => {
        if (props.loaded) {
            setTimeout(() => {
                setIsIntroFading(true);
            }, 6000);

            setTimeout(() => {
                setIsIntroVisible(false);
            }, 7500);
        }
    }, [props.loaded]);

    return (
        <div>
            {(props.loaded && isIntroVisible) &&
                <div className={`${styles.introContainer} ${(isIntroFading && styles.introFading)}`}>
                    <div className={styles.introIcon}></div>
                </div>
            }
        </div>
    );
}

export default ArWebIntro;

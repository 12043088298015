import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        modify_component: "Edit component to develop web ar",
    },
    it: {
        modify_component: "Modifica il componente per sviluppare l'ar web",
    }
});

export default localeStrings;
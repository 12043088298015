import { f7, FabBackdrop, Fab, FabButton, FabButtons, Icon, Link, List, ListItem, Navbar, Page, Popup, SwipeoutActions, SwipeoutButton, Tab, Tabs, Toolbar, Block, NavLeft, Button, NavTitle, NavRight, Popover } from 'framework7-react';
import React from 'react';
import { connect } from 'react-redux';
import { AnalyticsRegister, ANALYTICS_SCREEN } from '../../analytics-register';
import PkArDiscover from '../../pikkart-cms/ar/discover';
import PkCordova from '../../pikkart-cms/cordova';
import localeStrings from './discover-editor-local';
import appLocaleStrings from '../../app-local';
import ImageCard from './image-card';
import Webcam from 'webcam-easy';

class DiscoverViews extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            views: [],
            infoContents: [],
            images: [],
            layers: [],
            pictureSource: null,
            destinationType: null,
            webcam: null,
            tabViews: true,
            cameraPopupOpened: false,
        };

        this.openCamera = this.openCamera.bind(this);
        this.openGallery = this.openGallery.bind(this);
        this.onPhotoDataSuccess = this.onPhotoDataSuccess.bind(this);
    }

    /**
     * Funzione che restituisce il base64 dell'immagine selezionata o scattata (Cordova)
     * 
     * @param {string} imageData base64 dell'immagine
     */
    onPhotoDataSuccess(imageData) {
        console.log('on photo data success', imageData);
        const imagebase64 = "data:image/png;base64," + imageData;

        const img = document.getElementById("temp_image");
        this.resizeImage(imagebase64).then(resizedImageBase64 => {
            img.src = resizedImageBase64;
        })
        this.setState({ cameraPopupOpened: false });
    }

    /**
     * Funzione che restituisce un errore in caso di non riuscita cattura dell'immagine selezionata o scattata (Cordova)
     * 
     * @param {string} message messaggio d'errore
     */
    onFail(message) {
        f7.toast.create({
            text: 'Failed because: ' + message,
            closeTimeout: 2000,
        }).open();

        this.setState({ cameraPopupOpened: false })
    }

    /**
     * Funzione che aspetta che l'immagine venga caricata
     */
    waitForElement = () => {
        console.log('waitForElement');
        if (document.getElementById("temp_image").src !== "") {
            let imagebase64 = document.getElementById("temp_image").src;
            PkArDiscover.addModelView(this.props.modelData.model.uniqueId, imagebase64.substr(22))
                .then((res) => {
                    this.props.f7router.navigate('/discover-view-detail', {
                        props: {
                            modelData: this.props.modelData,
                            view: res.data,
                            image: imagebase64,
                            layer: null
                        }, reloadAll: true
                    });
                });
        } else {
            setTimeout(() => {
                this.waitForElement();
            }, 1500);
        }
    }
    /**
     * Funzione che crea una nuova view prendendo un'immagine base64
     * 
     * @param {string} imagebase64 base64 dell'immagine
     */
    createNewView = (imagebase64) => {
        this.resizeImage(imagebase64).then(resizedImageBase64 => {
            console.log('resized image saved', resizedImageBase64);
            PkArDiscover.addModelView(this.props.modelData.model.uniqueId, resizedImageBase64.substr(22)).then((res) => {
                this.props.f7router.navigate('/discover-view-detail', {
                    props: {
                        modelData: this.props.modelData,
                        view: res.data,
                        image: resizedImageBase64,
                        layer: null
                    }, reloadAll: true
                });
            })
        })
    }

    resizeImage = (base64Str, maxWidth = 480, maxHeight = 640) => {
        return new Promise((resolve) => {
            let img = new Image()
            img.src = base64Str
            img.onload = () => {
                let canvas = document.createElement('canvas')
                const MAX_WIDTH = maxWidth;
                const MAX_HEIGHT = maxHeight;
                var iw = img.width;
                var ih = img.height;
                var scale = Math.min((MAX_WIDTH / iw), (MAX_HEIGHT / ih));
                var iwScaled = iw * scale;
                var ihScaled = ih * scale;
                canvas.width = iwScaled;
                canvas.height = ihScaled;
                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
                resolve(canvas.toDataURL())
            }
        })
    }

    /**
     * Funzione che apre la camera su smartphone e scatta la foto su browser e 
     * crea una nuova view con immagine dalla camera
     */
    openCamera() {
        if (PkCordova.isCordova()) {
            const destinationType = this.state.destinationType;

            navigator.camera.getPicture(this.onPhotoDataSuccess, this.onFail, {
                quality: 100,
                destinationType: destinationType.DATA_URL,
                cameraDirection: navigator.camera.Direction.BACK,
                correctOrientation: true,
            });

            this.waitForElement();
        } else {
            const picture = this.state.webcam.snap();
            this.createNewView(picture);

            this.state.webcam.stop();
        }
    }

    /**
     * Funzione che crea una nuova view con immagine dalla galleria
     * 
     * @param {object} e oggetto che contiene informazioni sull'immagine selezionata
     */
    openGallery(event) {

        console.log('openGallery');

        if (PkCordova.isCordova()) {
            let destinationType = this.state.destinationType;
            let pictureSource = this.state.pictureSource;

            navigator.camera.getPicture(this.onPhotoDataSuccess, this.onFail, {
                quality: 100,
                destinationType: destinationType.DATA_URL,
                sourceType: pictureSource.PHOTOLIBRARY,
                correctOrientation: true,
            });
            this.waitForElement();
        } else {
            let base64 = "";
            let file = event.target.files[0];
            let reader = new FileReader();
            reader.readAsDataURL(file);

            reader.addEventListener('load', () => {
                base64 = reader.result;

                if (base64.substr(11, 4) === "jpeg") {
                    base64 = "data:image/png;base64," + base64.substr(23);
                }

                this.createNewView(base64);
            });
        }
    }

    /**
     * Funzione che avvia la webcam del pc
     */
    startWebcam = () => {
        const webcamElement = document.getElementById('webcam');
        const canvas = document.getElementById('temp_canvas');
        const webcam = new Webcam(webcamElement, 'user', canvas);
        this.setState({ webcam: webcam });
        webcam.start()
            .then((result) => {
                console.log("webcam started");
            })
            .catch((err) => {
                console.log(err);
            });
    }

    /**
     * Funzione che permette di modificare una view
     * 
     * @param {object} e oggetto della view da modificare
     */
    modifyView(e) {
        const temp_image = this.state.images.filter(x => { return x.uniqueId === e.uniqueId })[0];
        const temp_layer = this.state.layers.filter(x => { return x.uniqueId === e.uniqueId })[0];
        const image = temp_image ? temp_image.bit_image : "file not found";
        const layer = temp_layer ? temp_layer.bit_layer : "file not found";
        if (temp_image) {
            this.props.f7router.navigate('/discover-view-detail', { props: { modelData: this.props.modelData, view: e, image: image, layer: layer }, reloadAll: true })
        }
    }

    /**
     * Funzione che chiama l'api per processare il model
     */
    processModel() {
        this.showPreloader();
        PkArDiscover.processModel(this.props.modelData.model.uniqueId)
            .then((response) => {
                if (response.result.code !== 200) {
                    f7.toast.create({
                        text: localeStrings.process_model_error,
                        closeTimeout: 2000,
                    }).open();
                } else {
                    f7.toast.create({
                        text: localeStrings.process_model_success,
                        closeTimeout: 2000,
                    }).open();
                }
            })
            .finally(() => {
                this.hidePreloader();
            });
    }

    showPreloader = () => {
        f7.dialog.preloader();
    }
    hidePreloader = () => {
        f7.dialog.close();
    }

    /**
     * Funzione che chiama l'api che restituisce le immagini delle varie views
     * 
     * @param {object} e oggetto della view
     */
    getImage(e) {
        PkArDiscover.getModelViewImage(e.uniqueId)
            .then((res) => {
                let image = { uniqueId: e.uniqueId, bit_image: 'data:image/png;base64,' + res.data };
                this.setState({ images: this.state.images.concat(image) });
            });
    }

    /**
     * Funzione che chiama l'api che restituisce i layer delle varie views
     *
     * @param {object} e oggetto della view
     */
    getLayer(e) {
        PkArDiscover.getModelViewLayer(e.uniqueId)
            .then((res) => {
                let layer = { uniqueId: e.uniqueId, bit_layer: 'data:image/png;base64,' + res.data };
                this.setState({ layers: this.state.layers.concat(layer) });
            });
    }

    render() {
        const views = this.state.views.map((view, i) => {
            let image = this.state.images.filter(x => {
                return x.uniqueId === view.uniqueId
            })[0];
            let bit_image = image ? image.bit_image : null;

            if (bit_image) {
                return (
                    <ImageCard
                        key={view.id}
                        item={view}
                        id={view.id}
                        image={bit_image}
                        rating={view.rating}
                        keyPointsCount={view.keyPointsCount}
                        modifyView={() => {
                            this.modifyView(view)
                        }} />
                )
            }
            else { return null; }
        });

        const contents = this.state.infoContents.map(e => {
            return (
                <ListItem
                    swipeout
                    title={e.content.title}
                    text={e.content.descr}
                    onClick={() => {
                        this.props.f7router.navigate('/discover-content-detail', {
                            props:
                            {
                                infoContent: e.content,
                                modelData: this.props.modelData,
                                modifyInfoContent: true
                            },
                            reloadAll: true
                        })
                    }}>
                    <img src={e.content.imageUrl !== "" ? e.content.imageUrl : './img/discover_img_placeholder.jpg'} alt="model identifier" width="80" slot="media" className="lazy lazy-fade-in" />
                    <SwipeoutActions right>
                        <SwipeoutButton delete onClick={() => {
                            PkArDiscover.deleteModelContent(e.content.id);
                        }}>
                            {localeStrings.delete_content_option}
                        </SwipeoutButton>
                    </SwipeoutActions>
                </ListItem>

            )
        })

        const fabs =
            (PkCordova.isCordova() ?
                <Fab position="right-bottom" slot="fixed" >
                    <Icon ios="f7:plus" aurora="f7:plus" md="material:add"></Icon>
                    <Icon ios="f7:xmark" aurora="f7:xmark" md="material:close"></Icon>

                    <FabButtons position="top">
                        <FabButton
                            label={localeStrings.open_camera}
                            onClick={() => {
                                this.openCamera();
                            }}>
                            <Icon material="photo_camera"></Icon>
                        </FabButton>
                        <FabButton
                            label={localeStrings.open_gallery}
                            onClick={this.openGallery}>
                            <Icon material="collections"></Icon>
                        </FabButton>
                    </FabButtons>
                </Fab>
                :
                <div style={{ position: "fixed", bottom: "0", right: "0", zIndex: 100 }}>
                    <div className="fab fab-right-bottom">
                        <a href>
                            <i className="icon f7-icons if-not-md">plus</i>
                            <i className="icon f7-icons if-not-md">xmark</i>
                            <i className="icon material-icons md-only">add</i>
                            <i className="icon material-icons md-only">close</i>
                        </a>
                        <div className="fab-buttons fab-buttons-top">
                            <div onClick={() => { document.getElementById("openCamera").click() }}>
                                <a id="openCamera" className="button popup-open" href data-popup=".popup-camera" onClick={() => {
                                    this.setState({ cameraPopupOpened: true });
                                    this.startWebcam();
                                }} style={{ display: "none" }}></a>
                                <a className="fab-label-button" href>
                                    <span className="material-icons">photo_camera</span>
                                    <span className="fab-label">{localeStrings.open_camera}</span>
                                </a>
                            </div>
                            <div onClick={() => { document.getElementById("openGallery").click() }}>
                                <input id="openGallery" type="file" onChange={(e) => { this.openGallery(e) }} style={{ display: "none" }} />
                                <a className="fab-label-button" href>
                                    <span className="material-icons">collections</span>
                                    <span className="fab-label">{localeStrings.open_gallery}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )

        return (
            <Page
                pageContent={false}
                hideToolbarOnScroll
                hideBarsOnScroll
                hideNavbarOnScroll>
                {/* <PikkartNavbar
                        title={this.props.modelData.contentData.content.title}
                        logo='./img/logo/logo.png'
                        showNavRight={true}
                        showArrowAsNavRight={true} /> */}

                <Navbar>
                    <NavLeft backLink="Back"
                        onBackClick={() => {
                            this.props.f7router.navigate('/members-area', {
                                reloadAll: true
                            })
                        }}
                    />
                    <NavTitle style={{ textAlign: 'unset' }}>{this.props.modelData.contentData.content.title}</NavTitle>
                    <NavRight>
                        <Link
                            // iconOnly={true}
                            iconIos="f7:ellipsis_vertical"
                            iconAurora="f7:ellipsis_vertical"
                            iconMd="material:more_vert"
                            popoverOpen=".popover-process-menu" />
                    </NavRight>
                </Navbar>


                <div style={{ position: "fixed", bottom: "0", right: "0", zIndex: 100 }}
                    onClick={() => {
                        if (!this.state.tabViews) {
                            this.props.f7router.navigate('/discover-content-detail',
                                {
                                    props:
                                    {
                                        modelData: this.props.modelData,
                                        newContent: true
                                    },
                                    reloadAll: true
                                })
                        }
                    }}>



                    <FabBackdrop slot="fixed" />

                    {fabs}
                </div>
                <Toolbar tabbar position={'top'} >
                    <Link
                        tabLink="#tab-edit-discover-views"
                        tabLinkActive={!this.props.activeContentTab}
                        onClick={() => { this.setState({ tabViews: true }) }}>{localeStrings.views_tab_title}</Link>
                    <Link
                        tabLink="#tab-edit-discover-contents"
                        tabLinkActive={this.props.activeContentTab}
                        onClick={() => { this.setState({ tabViews: false }) }}>{localeStrings.contents_tab_title}</Link>
                </Toolbar>
                <Tabs animated>
                    <Tab id="tab-edit-discover-views" className="page-content" tabActive={!this.props.activeContentTab}>
                        {this.state.views.length > 0 ?
                            views
                            :
                            <Block className='error-block'>
                                {appLocaleStrings.no_contents}
                                {/* {String(this.state.arScenesErrorMessage)} */}
                            </Block>
                        }
                    </Tab>
                    <Tab id="tab-edit-discover-contents" className="page-content" tabActive={this.props.activeContentTab}>
                        {contents && contents.length > 0 ?
                            <List mediaList noHairlines noHairlinesBetween>
                                {contents}
                            </List>
                            :
                            <Block className='error-block'>
                                {appLocaleStrings.no_contents}
                            </Block>
                        }
                    </Tab>
                </Tabs>

                <Popup
                    className="popup-camera"
                    opened={this.state.cameraPopupOpened && !PkCordova.isCordova()}>
                    <Page pageContent={false} colorTheme="custom">
                        <Navbar mdCenterTitle={true}>
                            <NavLeft>
                                <Link popupClose onClick={() => { this.state.webcam.stop(); }} iconOnly={true} color="black" iconIos="f7:xmark" iconAurora="f7:xmark" iconMd="material:close" />
                            </NavLeft>
                        </Navbar>
                        <Block>
                            <video id="webcam" autoPlay playsInline style={{ width: "100%" }}></video>
                            <Button className="popup-close" onClick={this.openCamera} large fill> {localeStrings.take_picture}</Button >
                        </Block>
                        <img id="temp_image" style={{ display: "none" }} alt="snapped" />
                        <canvas id="temp_canvas" style={{ display: "none" }} />
                    </Page>
                </Popup>

                <Popover className='popover-process-menu'>
                    <List>
                        <ListItem
                            className="popover-close"
                            onClick={() => this.processModel()}
                            title={localeStrings.process} />
                    </List>
                </Popover>
            </Page>
        )
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.DISCOVER_MODEL_EDITOR);
        //TODO: CONTROLLI SU VALIDITà DI MODEL
        PkArDiscover.getModelViews(this.props.modelData.model.uniqueId)
            .then((res) => {
                if (this.props.deletedView) {
                    let temp_view = res.data.filter(x => { return x.uniqueId !== this.props.deletedView });

                    res.data = temp_view;
                }
                this.setState({ views: res.data });
                res.data.forEach((e) => {
                    this.getImage(e);
                    this.getLayer(e);
                });
            }, (error) => {
                console.log(error);
            });

        //TODO: GESTIRE ERRORI
        PkArDiscover.getKeypointContentsData(this.props.modelData.model.contentId)
            .then((res) => {
                if (res.data && res.data.items.length > 0) {
                    this.setState({ infoContents: res.data.items });
                }
            });

        if (PkCordova.isCordova()) {
            this.setState({
                pictureSource: navigator.camera.PictureSourceType,
                destinationType: navigator.camera.DestinationType
            }, () => {
                console.log('pictureSource', this.state.pictureSource);
                console.log('destinationType', this.state.destinationType);
            });
        }

        if (this.props.activeContentTab) {
            this.setState({ tabViews: false });
        }
    }
}

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(DiscoverViews);
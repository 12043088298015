import React, {useEffect} from 'react';
import styles from './ar-web.module.css';
import {Block, Col, Page, Row} from "framework7-react";

function ArWebLoading(props) {
    const [isLoadVisible, setIsLoadVisible] = React.useState(true);
    const [isLoadFading, setIsLoadFading] = React.useState(false);

    useEffect(() => {
        if (props.loaded) {
            setTimeout(() => {
                setIsLoadFading(true);
            }, 400);
            setTimeout(() => {
                setIsLoadVisible(false);
            }, 1000);
        }
    }, [props.loaded]);

    return (
        <div>
            {isLoadVisible &&
                <Page className={`${styles.loaderContainer} ${(isLoadFading && styles.loaderContainerFaded)}`}
                      style={{backgroundColor: process.env.REACT_APP_SPLASH_SCREEN_BACKGROUND_COLOR}}>
                    <Block textAlign="center">
                        <Row style={{width: '100%'}}>
                            <Col style={{textAlign: 'center'}}>
                                <div className={styles.loaderCenter}>
                                    <img src={'/img/logo/logo_splash.png'} alt="logo"
                                         className={styles.loaderLogo}/>
                                    <div className={styles.loaderBarContainer}>
                                        <div className={styles.loaderBarProgress}
                                             style={{width: `${props.loadingProgress}%`}}></div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Block>
                </Page>
            }
        </div>
    );
}

export default ArWebLoading;

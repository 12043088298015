import { Pk } from "../../index";
import { PkCrypto } from "../../crypto/crypto";
import { PkApiNet } from "../../api/net";

export class PkApiRoomsParticipants {
  static getParticipants = (roomUniqueId) => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "get",
        url:
          Pk.getConfig().apiUrl +
          "/services/rooms/" +
          roomUniqueId +
          "/participants",
      })
        .then(function (response) {
          if (response.result.success === true) {
            let stringData = PkCrypto.decrypt(
              response.data,
              response.cryptoSchema
            );
            let data = JSON.parse(stringData);
            response.data = data;
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
}

export default PkApiRoomsParticipants;

/*
import { combineReducers } from 'redux'
import appReducer from './appReducer';

export default () => {
    
    return combineReducers({
        app : appReducer
    });

} 
*/


import { combineReducers } from 'redux'
import appReducer from './appReducer';
import {PkReduxReducer} from '../pikkart-cms/redux/reducer';

export default () => {
    
    return combineReducers({
        app : appReducer,
        cms : cmsReducer
    });

    function cmsReducer (state, action) {
        PkReduxReducer.setReducerName('cms');
        return PkReduxReducer.getReducer(state, action);
    }
} 

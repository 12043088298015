import { PkLog } from "./log";

export class PkCommons {
  static injectJsScript = (scriptId, scriptLink) => {
    return new Promise((resolve, reject) => {
      const loadedResult = (loadStatus) => {
        if (loadStatus === "loaded") {
          resolve();
        } else if (loadStatus === "failed") {
          reject();
        } else {
          PkLog.error("invalid load status: " + loadStatus);
          reject();
        }
      };

      const existingscript = document.getElementById(scriptId);
      if (!existingscript) {
        const script = document.createElement("script");
        script.setAttribute("async", "");
        script.setAttribute("id", scriptId);
        script.setAttribute("type", "text/javascript");
        script.setAttribute("load-status", "loading");
        script.addEventListener("load", () => {
          script.setAttribute("load-status", "loaded");
          loadedResult("loaded");
        });
        script.addEventListener("error", (e) => {
          script.setAttribute("load-status", "failed");
          loadedResult("failed");
        });
        script.src = scriptLink;
        const node = document.getElementsByTagName("script")[0];
        node.parentNode.insertBefore(script, node);
      } else {
        let loadStatus = existingscript.getAttribute("load-status");
        if (loadStatus === "loading") {
          PkCommons.waitUntil(() => {
            loadStatus = existingscript.getAttribute("load-status");
            return loadStatus !== "loading";
          }, 500).then(() => {
            loadedResult(loadStatus);
          });
        } else {
          loadedResult(loadStatus);
        }
      }
    });
  };

  static waitUntil = (callbackCondition, interval, timeLimit) => {
    return new Promise((resolve, reject) => {
      if (!callbackCondition) {
        reject("conditionCallback is not defined");
      }
      if (!timeLimit) {
        timeLimit = 20; //20 secondi
      }
      timeLimit = timeLimit * 1000;

      let startTime = new Date().getTime();

      let timeoutIntervalId = setInterval(() => {
        if (new Date().getTime() - startTime > timeLimit) {
          clearInterval(intervalId);
          clearInterval(timeoutIntervalId);
          reject("timeout");
        }
      }, 1000);

      let intervalId = setInterval(
        () => {
          if (callbackCondition()) {
            clearInterval(intervalId);
            clearInterval(timeoutIntervalId);
            resolve();
          }
        },
        interval ? interval : 50
      );
    });
  };

  static insertAt = (array, index, ...elements) => {
    array.splice(index, 0, ...elements);
  };

  static getElementIndexFromUniqueId = (array, uniqueId) => {
    for (let i = 0; i < array.length; i++) {
      if (array[i].UniqueId === uniqueId) {
        return i;
      }
    }
    return -1;
  };

  static moveArrayElement(arr, oldIndex, newIndex) {
    if (newIndex >= arr.length) {
      var k = newIndex - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
    return arr; // for testing
  }

  static changeArrayItemsWithDelay = (
    array,
    index,
    changeItemCallback,
    delay,
    progressCallback,
    finishCallback
  ) => {
    if (array.length === 0) {
      finishCallback(array);
    } else {
      array[index] = changeItemCallback(array[index]);
      index = index + 1;
      var perc = Math.round((index * 100) / array.length);

      if (progressCallback) {
        progressCallback(perc);
      }

      if (index < array.length) {
        setTimeout(() => {
          PkCommons.changeArrayItemsWithDelay(
            array,
            index,
            changeItemCallback,
            delay,
            progressCallback,
            finishCallback
          );
        }, delay);
      } else {
        finishCallback(array);
      }
    }
  };

  static changeArrayItemsAsync = (
    array,
    changeItemCallback,
    delay,
    progressCallback
  ) => {
    return new Promise((resolve, reject) => {
      let index = 0;
      PkCommons.changeArrayItemsWithDelay(
        array,
        index,
        changeItemCallback,
        delay,
        progressCallback,
        function (changedItems) {
          resolve(changedItems);
        }
      );
    });
  };
}
export default PkCommons;

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions';
import { NavLeft, Link, NavTitle, NavRight, Searchbar, Popup, Page, Navbar, List, ListItem, Block, BlockTitle, Row, Col, Button } from 'framework7-react';
import localeStrings from './pk-navbar-local';

class PikkartNavbar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tempCurrentFilteredCustomer: "",
            currentFilteredCustomer: "",
            tempOnlyPrivateContentsVisible: true,
            onlyPrivateContentsVisible: true,
        }
    }

    getBackButton = () => {
        if (this.props.showArrowAsNavRight) {
            return (<Link onClick={() => { if (this.props.onBack) this.props.onBack() }} back animate={true} iconIos="f7:arrow_left" iconAurora="f7:arrow_left" iconMd="material:arrow_back" ></Link>);
        }
        else {
            return (
                <img slot="media" src={this.props.logo}
                    className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />
            )
        }
    }

    getSpacingIcon = () => {
        if (this.props.showChangeCustomerButton || this.props.showLogoutButton || this.props.searchBarEnabled || this.props.allowPrivateContents) { return ""; }
        else {
            return (
                <img slot="media" src='./img/spacing_icon.png'
                    className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />
            )
        }
    }

    getChangeCustomerButton = () => {
        if ((this.props.userCustomers && this.props.userCustomers.length > 0)
            || this.props.allowPrivateContents) {

            if (this.props.showChangeCustomerButton || this.props.allowPrivateContents) {
                return (
                    <NavRight>
                        <Link
                            popupOpen="#popup-customers-filter"
                            style={{ color: 'white' }}
                            iconIos="material:filter_list_alt"
                            iconAurora="material:filter_list_alt"
                            iconMd="material:filter_list_alt"
                        />
                    </NavRight >
                )
            }
        }
    }

    getSearchBar = () => {
        if (this.props.searchBarEnabled) {
            return (
                <Fragment>
                    <NavRight>
                        <Link
                            searchbarEnable={"." + this.props.searchBarId}
                            iconIos="f7:search"
                            iconAurora="f7:search"
                            iconMd="material:search"
                        ></Link>
                    </NavRight>
                    <Searchbar
                        id={this.props.searchBarId}
                        className={this.props.searchBarId}
                        expandable
                        customSearch={true}
                        onSearchbarSearch={this.props.onSearch}
                        placeholder={localeStrings.search_placeholder_text}
                    ></Searchbar>
                </Fragment>
            )
        }
    }

    //logoutButtonClicked
    getLogoutButton = () => {
        if (this.props.showLogoutButton) {
            return (
                <NavRight>
                    <Link
                        onClick={this.props.logoutButtonClicked}
                        style={{ color: 'white' }}
                        iconIos="f7:person_crop_circle_badge_xmark"
                        iconAurora="f7:person_crop_circle_badge_xmark"
                        iconMd="f7:person_crop_circle_badge_xmark" />
                </NavRight>
            )
        } else return null;
    }

    getMenuButton = () => {
        if (this.props.showMenu) {
            return <Link panelOpen="left" color="black" iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu"></Link>
        }
        else {
            return <img slot="media" src='./img/spacing_icon.png'
                className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />;
        }
    }

    filterPopupLayout = () => {
        if ((this.props.userCustomers && this.props.userCustomers.length > 0) || this.props.allowPrivateContents) {
            const customersListItemArray = this.props.userCustomers.map((item, index) => {
                return (
                    <ListItem
                        id={'customer-' + item.Code}
                        radio
                        radioIcon="start"
                        title={item.Name}
                        value={item.Code}
                        name="radio-customers"
                        checked={this.state.currentFilteredCustomer === item.Code}
                        onChange={() => {
                            this.setState({ currentFilteredCustomer: item.Code })
                        }} />
                )
            });

            return (
                <div>
                    <Block>
                        <BlockTitle>{localeStrings.options_group_customers}</BlockTitle>
                        <List>
                            {customersListItemArray}
                        </List>
                    </Block>
                    <Block>
                        <BlockTitle>{localeStrings.options_group_contents}</BlockTitle>
                        <List>
                            <ListItem
                                id={'content-private'}
                                radio
                                radioIcon="start"
                                title={localeStrings.private_contents_title_text}
                                value={'content-private'}
                                name="radio-contents"
                                checked={this.state.onlyPrivateContentsVisible}
                                onChange={() => {
                                    this.setState({ onlyPrivateContentsVisible: true })
                                }} />
                            <ListItem
                                id={'content-public'}
                                radio
                                radioIcon="start"
                                title={localeStrings.public_contents_title_text}
                                value={'content-public'}
                                name="radio-contents"
                                checked={!this.state.onlyPrivateContentsVisible}
                                onChange={() => {
                                    this.setState({ onlyPrivateContentsVisible: false })
                                }} />
                        </List>
                    </Block>

                    <Block>
                        <Row>
                            <Col>
                                <Button
                                    text={localeStrings.filter_button_cancel}
                                    onClick={() => {
                                        this.setState({
                                            onlyPrivateContentsVisible: this.state.tempOnlyPrivateContentsVisible,
                                            currentFilteredCustomer: this.state.tempCurrentFilteredCustomer,
                                        });
                                        this.props.setOnlyPrivateContentsVisible(this.state.tempOnlyPrivateContentsVisible);
                                        this.props.setCurrentFilteredCustomer(this.state.tempCurrentFilteredCustomer);
                                    }}
                                    outline />
                            </Col>
                            <Col>
                                <Button
                                    text={localeStrings.filter_button_apply}
                                    onClick={() => {
                                        this.setState({
                                            tempOnlyPrivateContentsVisible: this.state.onlyPrivateContentsVisible,
                                            tempCurrentFilteredCustomer: this.state.currentFilteredCustomer,
                                        });
                                        this.props.setOnlyPrivateContentsVisible(this.state.onlyPrivateContentsVisible);
                                        this.props.setCurrentFilteredCustomer(this.state.currentFilteredCustomer);

                                        this.props.applyFiltersButtonClicked(this.state.currentFilteredCustomer, this.state.onlyPrivateContentsVisible);
                                    }}
                                    fill
                                    popupClose />
                            </Col>
                        </Row>
                    </Block>
                </div>
            )
        }
    }

    componentDidMount = () => {
        this.setState({
            onlyPrivateContentsVisible: this.props.onlyPrivateContentsVisible,
            tempOnlyPrivateContentsVisible: this.props.onlyPrivateContentsVisible,
        });
    }

    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.defaultCustomer !== prevProps.defaultCustomer) {
            const defaultCustomerCode = this.props.defaultCustomer ? this.props.defaultCustomer.Code : null;
            this.setState({
                currentFilteredCustomer: this.props.currentFilteredCustomer ? this.props.currentFilteredCustomer : defaultCustomerCode,
                tempCurrentFilteredCustomer: this.props.currentFilteredCustomer ? this.props.currentFilteredCustomer : defaultCustomerCode,
            })
        }
    }

    render() {
        return (
            <Fragment>
                <NavLeft>
                    {JSON.parse(this.props.showNavRight) && this.props.showArrowAsNavRight ?
                        <img slot="media" src='./img/spacing_icon.png'
                            className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />
                        :
                        this.getMenuButton()
                    }
                </NavLeft>
                {this.props.showLogoutButton ?
                    <NavLeft>
                        <img slot="media" src='./img/spacing_icon.png'
                            className="icon material-icons" style={{ fontSize: '-webkit-xxx-large', paddingRight: '10px' }} alt='app_icon' />
                    </NavLeft> : null}
                <NavTitle>{this.props.title}</NavTitle>
                {this.getChangeCustomerButton()}
                {this.getSearchBar()}
                {this.getLogoutButton()}
                <NavRight>
                    {JSON.parse(this.props.showNavRight) ? this.getBackButton() : this.getSpacingIcon()}
                </NavRight>

                <Popup
                    id='popup-customers-filter'>
                    <Page
                        colorTheme="custom">
                        <Navbar mdCenterTitle={true}>
                            <NavRight>
                                <Link
                                    onClick={() => {
                                        this.setState({ onlyPrivateContentsVisible: this.state.tempOnlyPrivateContentsVisible });
                                        this.props.setOnlyPrivateContentsVisible(this.state.tempOnlyPrivateContentsVisible);
                                    }}
                                    popupClose
                                    iconOnly={true}
                                    color="black"
                                    iconIos="f7:xmark"
                                    iconAurora="f7:xmark"
                                    iconMd="material:close" />
                            </NavRight>
                        </Navbar>
                        {this.filterPopupLayout()}
                    </Page>
                </Popup>
            </Fragment>
        )
    }
}
// #region Redux
const mapStateToProps = state => {
    return {
        currentFilteredCustomer: state.app.currentFilteredCustomer,
        onlyPrivateContentsVisible: state.app.onlyPrivateContentsVisible,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        setCurrentFilteredCustomer: (currentFilteredCustomer) => dispatch(actions.setCurrentFilteredCustomer(currentFilteredCustomer)),
        setOnlyPrivateContentsVisible: (onlyPrivateContentsVisible) => dispatch(actions.setOnlyPrivateContentsVisible(onlyPrivateContentsVisible)),
    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(PikkartNavbar);
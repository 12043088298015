import React from 'react';
import { connect } from 'react-redux';
import { f7, View, Navbar, Page, PageContent, NavLeft, Link, List, Button, Popup, NavRight } from 'framework7-react';
import { PkCordovaARSdk, PkCordovaPermissions } from 'pikkart-cordova';
//import localeStrings from './main-local';
import * as actions from '../../redux/actions';
import localeStrings from './main-local';
import { AnalyticsRegister, ANALYTICS_SCREEN } from '../../analytics-register'
import { PkLog } from '../../pikkart-cms/log';
import TutorialHelper from '../../helpers/tutorial-helper';

class Main extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      menuVoices: [],
    };
  }

  render() {

    const navBar = (
      <Navbar id="main-navbar" transparent={true} sliding={false}>
        <NavLeft>
          <Link iconOnly={true} panelOpen="left" color="black" iconIos="f7:menu" iconAurora="f7:menu" iconMd="material:menu"></Link>
        </NavLeft>
      </Navbar>);

    const intestazioneImage = (
      <div className="titolo-home">
        <div className="titolo-container">
          <figure>
            <img src={'./img/logo/logo.png'} className="" alt="intestazione" />
          </figure>
          <div className="accent-font">{localeStrings.welcome}</div>
          <div className="nome-ente">Reggio Emilia</div>
        </div>
      </div>
    );

    let arButton = <Button style={{ marginTop: '20px' }} raised fill className="col button color-red mediateca" popupOpen=".popup-tutorial-ar">{localeStrings.arbutton_text}</Button>
    if (this.props.skippedArLogoTutorial) {
      arButton = <Button style={{ marginTop: '20px' }} raised fill className="col button color-red mediateca" onClick={(e) => { this.startMarkerRecognition() }}>{localeStrings.arbutton_text}</Button>
    }

    const arLogoVisitHome = (
      <List className="lista-record media-list no-chevron" style={{ marginTop: '50px' }}><ul>
        <li>
          <Link href="#0" className="item-link item-content">
            <div className="item-inner">
              <div className="header-lista">

                {/*<figure className="img-lista" style={{ background: '.../img/logo/arlogo.png' }}></figure>*/}
                <figure className="img-lista">
                  <img src="./img/logo/arlogo.png" alt="A description of what this is" />
                </figure>
                <div className="titolo-lista">
                  <div className="titolo">AR Logo</div>
                  <div className="data">{localeStrings.arlogo_text}</div>
                </div>
              </div>
            </div>
          </Link>
          {arButton}
        </li>
      </ul>
      </List>
    )

    const arlogoPopup = (
      <Popup
        tabletFullscreen={true} push className="popup-tutorial-ar" closeOnEscape={true}>
        <View>
          <Page>
            <Navbar transparent={true}>
              <NavRight>
                <Link popupClose iconOnly={true} color="black" iconIos="f7:xmark" iconAurora="f7:xmark" iconMd="material:close" />
              </NavRight>
            </Navbar>

            {TutorialHelper.getArLogoMainPopupTutorial(this.toggleClicked, this.startMarkerRecognition)}

            {/* <Swiper params={{ observer: true, observeParents: true }}>
              <SwiperSlide>
                <div style={{ textAlign: 'center' }}>
                  <img src={'./img/tutorial1-ar.png'} alt='tutorial-first-page' style={{ width: '80%' }} />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <p>Inquadra il logo di <br /> Reggio smART</p>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div style={{ textAlign: 'center' }}>
                  <img src={'./img/tutorial2.png'} alt='tutorial-second-page' style={{ width: '80%' }} />
                </div>
                <div style={{ textAlign: 'center' }}>
                  <p>Accederai a contenuti digitali esclusivi: <br /> video, gallerie di immagini, schede informative<br /> e molto altro...!</p>
                </div>
              </SwiperSlide>
            </Swiper>


            <div style={{ textAlign: 'right' }}>
              <span>Non mostrare più</span>
              <Toggle onToggleChange={(e) => { this.toggleClicked(e) }} init color="red"></Toggle>
            </div>

            <Button onClick={(e) => { this.startMarkerRecognition() }} style={{ marginTop: '15px' }} className="col button button-raised button-fill color-red popup-close">Avvia</Button> */}

          </Page>
        </View>
      </Popup>
    )


    return (
      <Page colorTheme="custom" id="main-page">

        {navBar}

        <PageContent className="homepage" style={{ paddingTop: 0 }}>
          {intestazioneImage}

          {arLogoVisitHome}

          {arlogoPopup}
        </PageContent>
      </Page>
    )
  }

  componentDidMount = () => {
    AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.MAIN);

    const skippedArLogoTutorial = JSON.parse(localStorage.getItem('skip_ar_logo_tutorial'));
    if (skippedArLogoTutorial != null)
      this.props.setSkippedArLogoTutorial(JSON.parse(skippedArLogoTutorial));
  }

  startMarkerRecognition = () => {
    let _dialog = null;

    let apiUrl = process.env.REACT_APP_CMS_API_URL;
    let enableGlobalRecognition = JSON.parse(process.env.REACT_APP_ENABLE_GLOBAL_RECOGNITION);
    let enableFloating = JSON.parse(process.env.REACT_APP_START_AR_IN_FLOATING_MODE);
    let enableFloatingScenesWithVideoOnly = JSON.parse(process.env.REACT_APP_FLOATING_SCENES_WITH_VIDEO_ONLY);
    let showMaskOverCamera = JSON.parse(process.env.REACT_APP_SHOW_MASK_OVER_CAMERA_ON_AR);

    const startRecognitionFragment = () => {
      this.props.setActivityPaused(true);
      PkCordovaARSdk.startRecognition(apiUrl, "?contentid=", enableGlobalRecognition, enableFloating, enableFloatingScenesWithVideoOnly, showMaskOverCamera);//apiUrl, cmsContentPageUrl, enableGlobalRecognition
    }

    const downloadMarkers = () => {
      PkCordovaPermissions.askStoragePermission().then((status) => {
        openDeterminedProgress();
        PkCordovaARSdk.downloadMarkers(apiUrl, finished, error, progress);
      }).catch((err) => {
        PkLog.error(err);

        openErrorDialog(err);
      });

      const finished = () => {
        PkLog.log('Download markers finished');
        _dialog.setText(localeStrings.loading_markers_complete)

        closeDialog();

        startRecognitionFragment();
      };

      const progress = (progressValue) => {
        PkLog.log('Download markers progress ' + progressValue);
        if (_dialog)
          _dialog.setProgress(progressValue);
        Math.round(progressValue)
        _dialog.setText(localeStrings.formatString(localeStrings.loading_markers_percentage, progressValue));
      };

      const error = () => {
        _dialog.setText(localeStrings.loading_markers_error);
        closeDialog();
      };

      const openDeterminedProgress = () => {
        const app = f7;
        let progress = 0;
        _dialog = app.dialog.progress(localeStrings.download_title, progress);
        _dialog.setText(localeStrings.download_text);
      }

      const openErrorDialog = (error) => {
        PkLog.error(error);
        const app = f7;
        _dialog = app.dialog.alert(error);
        _dialog.setTitle('Error');
      }
    }

    const closeDialog = () => {
      setTimeout(function () {
        _dialog.close();
      }, this.state.dialogClosingTime)
    }

    if (enableGlobalRecognition) {
      startRecognitionFragment();
    } else {
      downloadMarkers();
    }
  }

  toggleClicked = (selected) => {
    this.props.setSkippedArLogoTutorial(selected);
    localStorage.setItem('skip_ar_logo_tutorial', selected);
  }
};

// #region Redux
const mapStateToProps = state => {
  return {
    skippedArLogoTutorial: state.app.skippedArLogoTutorial,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setSkippedArLogoTutorial: (skippedArLogoTutorial) => dispatch(actions.setSkippedArLogoTutorial(skippedArLogoTutorial)),
    setActivityPaused: (activityPaused) => dispatch(actions.setActivityPaused(activityPaused)),
  }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Main);
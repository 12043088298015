import React from 'react';
import { connect } from 'react-redux';
import { f7, Block, BlockHeader, Page, Navbar } from 'framework7-react';
import { AnalyticsRegister, ANALYTICS_SCREEN } from '../../analytics-register'
import Commons from '../../commons'
import PikkartNavbar from '../../components/pk-navbar/pk-navbar';

class Credits extends React.Component {

    constructor(props) {
        super(props);

        this.state = {

        };
    }

    render() {
        let menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);
        return (
            <Page>
                <Navbar>
                    <PikkartNavbar
                        title={menuItem.title}
                        logo='./img/logo/logo.png'
                        showNavRight={process.env.REACT_APP_SHOW_ICON_ON_NAV_BAR}
                        showArrowAsNavRight={false}
                        showMenu={Commons.isSideMenuAllowed(f7)} />
                </Navbar>
                <BlockHeader></BlockHeader>
                <Block>
                    <div style={{ textAlign: 'center' }}
                        onClick={() => { window.open(process.env.REACT_APP_CREDITS_SCREEN_REDIRECT_URL, '_blank') }}>
                        <img src={'./img/landing-image.png'} alt="logo" width="90%" />
                    </div>
                </Block>
            </Page>
        )
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.CREDITS);
    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

// #endregion

export default connect(mapStateToProps, mapDispatchToProps)(Credits);
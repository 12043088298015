import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    near: "NEAR",
    info: "INFO",
    map: "MAP",
    places: "PLACES",
    tutorial_toggle_label: "Do not show again",
    tutorial_button_label: "Start",
    tutorial_1: "Point the camera at artifacs inside the museum",
    tutorial_2: "You will access exclusive digital content: videos, image galleries, information cards and much more ...!",
  },
  it: {
    near: "DINTORNI",
    info: "INFO",
    map: "MAPPA",
    places: "LUOGHI",
    tutorial_toggle_label: "Non mostrare più",
    tutorial_button_label: "Avvia",
    tutorial_1: "Inquadra gli artefatti all'interno del museo",
    tutorial_2: "Accederai a contenuti digitali esclusivi: video, gallerie di immagini, schede informative e molto altro...!",
  }
});
  
  export default localeStrings;

import { CONTENT_VIEW } from './pikkart-cms/contents';
import { PkApiAuth } from './pikkart-cms/api/auth';
import { PkLog } from './pikkart-cms/log';
import { f7 } from 'framework7-react';
import { isIOS } from "react-device-detect";

export class Commons {

    static getMenuItemUrl = (item) => {
        let stringLink = item.code ? item.code : (item.items.length === 0 ? "/content-list/" : "/menu-list/");
        stringLink += '?cid=' + item.id;
        return stringLink;
    }

    static getContentDetailUrl = (content) => {
        let stringLink = "/content-detail/" + content.id;
        return stringLink;
    }

    static getRequestedContentDetailUrl = (content) => {
        let stringLink = "/requested-content-detail/" + content.id;
        return stringLink;
    }

    static getMenuItemFromId = (itemId, list, catIndex) => {

        catIndex = (!catIndex) ? 1 : catIndex + 1;

        for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.id === itemId) {
                item.catIndex = catIndex;
                return item;
            }

            if (item.items && item.items.length > 0) {
                item = Commons.getMenuItemFromId(itemId, item.items, catIndex);
                if (item !== null) {
                    return item;
                }
            }
        }
        return null;
    }

    static getMenuItemFromCode = (itemCode, list, catIndex) => {

        catIndex = (!catIndex) ? 1 : catIndex + 1;

        for (let i = 0; i < list.length; i++) {
            let item = list[i];

            if (item.code === itemCode) {
                item.catIndex = catIndex;
                return item;
            }

            if (item.items && item.items.length > 0) {
                item = Commons.getMenuItemFromCode(itemCode, item.items, catIndex);
                if (item !== null) {
                    return item;
                }
            }
        }
        return null;
    }

    static getMenuItemFromRoute = (route, menu) => {
        let item = null;

        if (route.query && route.query.cid) {
            let cid = parseInt(route.query.cid);
            if (!isNaN(cid)) {
                item = Commons.getMenuItemFromId(cid, menu);
            }
        }

        if (!item) {
            item = Commons.getMenuItemFromCode(route.path, menu);
        }

        return item;
    }

    static checkAuthentication = (self, router, redirectPage) => {
        if (!router) {
            console.error('router param is null')
            return;
        }
        else if (!router.currentRoute) {
            console.error('router.currentRoute param is null')
            return;
        }

        let authenticated = PkApiAuth.isAuthenticated();

        if (!authenticated /*&& router.currentRoute.url !== "/login/"*/) {
            PkLog.log('checkAuthentication failed...going to login');

            //chiudo tutti i popup
            // let popups = self.$$('.ra-popup');
            const popups = f7.popup.get();
            if (popups && popups.length > 0) {
                popups.forEach(popup => {
                    f7.popup.close('#' + popup.id, true);
                })
            }
            //rimando al login
            setTimeout(function () {
                if (router) {
                    router.navigate('/login/', { props: { redirectPage: redirectPage } });
                }
            }, 500);
        }
    };

    static getStringContentView = (contentViewInt) => {
        switch (contentViewInt) {
            case CONTENT_VIEW.INFOCONTENT:
                return "InfoContent";
            case CONTENT_VIEW.PLACE:
                return "Place";
            case CONTENT_VIEW.BUSINESS_CARDS:
                return "BusinessCard";
            case CONTENT_VIEW.COMPANY:
                return "Company";
            case CONTENT_VIEW.DISCOVER_MODEL:
                return "DiscoverModel";
            case CONTENT_VIEW.MARKERLESS:
                return "Markerless";
            case CONTENT_VIEW.DISCOVER_KEYPOINT:
                return "DiscoverKeypoint";
            case CONTENT_VIEW.ITINERARY:
                return "Itinerary";
            case CONTENT_VIEW.AR_SCENE:
                return "ArScene";
            default:
                return 0;
        }
    }

    static isSideMenuAllowed = (f7) => {
        if (JSON.parse(process.env.REACT_APP_USE_TOOLBARMENU_ONLY)) {
            return false
        }
        else {
            return !isIOS
        }
        /*&&
        (f7.$(window).width() < f7.$(window).height())*/
    };
}

export default Commons;
import React from 'react';
import { connect } from 'react-redux';
import { f7, Link, Block, Button } from 'framework7-react';
import MapView from '../content-elements/map-view';
import ContactList from '../content-elements/contact-list';
import ImageGallery from '../content-elements/image-gallery';
import { PkCordovaChecklistAutomation } from 'pikkart-cordova';
import localeStrings from './checklist-automation-view-local';
import Commons from '../../commons';
import PkArDeep from '../../pikkart-cms/ar/deep';
import Workstations from './workstations';
import PkContents from '../../pikkart-cms/contents';
import Lottie from "lottie-react";
import animationData from '../../assets/lottie/check-verde-animation.json'

class ChecklistAutomationView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dialogClosingTime: 500,
            animationClosingTime: 300,
            workstationSuccessEvent: false,
            nextContent: null,
        };
    }

    render() {

        const content = this.props.content;

        const startChecklistAutomationButton = <Button className='start-content-experience-button'
            large
            fill
            onClick={(e) => { this.startChecklistAutomation(content.title) }}>
            {this.props.checklistAutomationButtonText}
        </Button>


        const shareLink = (content.googleUrl && content.googleUrl !== "" ?
            <Link
                className='material-icons'
                iconIos="material:share"
                iconMd="material:share"
                color='black'
                onClick={() => { this.props.shareContentHandler(content.googleUrl) }} /> :
            "");

        let contentDescr = "";
        contentDescr = content.descr.split("\n").map(function (item, idx) {
            return (
                <span key={idx}>
                    {item}
                    <br />
                </span>
            )
        })
        let checkAnimation = (this.state.workstationSuccessEvent ?
            <div>
                <Lottie
                    style={{
                        position: 'absolute',
                        bottom: '-100%',
                        left: '30%',
                        right: '30%',
                        zIndex: 2
                    }}
                    autoplay={true} loop={false}
                    animationData={animationData}
                    onComplete={(e) => {
                        this.animationEnded(e)
                    }} />
            </div>
            :
            "")

        return (
            <Block style={{ marginTop: 0 }}>

                <div className="video-container">


                    {checkAnimation}

                    <div style={{ textAlign: 'right' }}>
                        {shareLink}
                        {startChecklistAutomationButton}
                    </div>
                </div>

                <div>
                    <p>{contentDescr}</p>

                    {((content.lat !== 0 && content.long !== 0) ? <MapView content={content} /> : null)}

                    <ContactList content={content} />
                </div>

                <ImageGallery
                    imageArray={content.imageUrls}
                    galleryTitle={this.props.galleryTitle} />
            </Block>
        )
    }

    startChecklistAutomation = (workstationName) => {
        let _dialog = null;

        /**
         * DEBUG OTTENIMENTO IMMAGINE DA PLUGIN
         */
        // const result = Workstations.getDebugWorkstationByName(workstationName);
        // console.log('debug image result', result);
        // this.sendImageToServer(result);

        PkCordovaChecklistAutomation.startChecklistAutomation(workstationName).then((result) => {
            this.sendImageToServer(result);
        }).catch((err) => {
            console.log('startChecklistAutomation error', err)
            openErrorDialog(localeStrings.no_plugin_found_error);
        });

        const openErrorDialog = (error) => {
            const app = f7;
            _dialog = app.dialog.alert(error);
            _dialog.setTitle('Error');
        }

        const closeDialog = () => {
            setTimeout(function () {
                _dialog.close();
            }, this.state.dialogClosingTime)
        }
    }

    sendImageToServer = (result) => {
        return new Promise((resolve, reject) => {
            const self = this;

            this.showPreloader(true, localeStrings.loading_text, () => {
                let name = result.workstationName;
                let image = result.image;
                if (typeof result === "string") {

                    const obj = JSON.parse(result);
                    image = obj.image;
                    name = obj.workstationName;
                }
                const base64result = image;

                console.log('sending image to server', image)
                PkArDeep.processDeepModel({ image: base64result, station_name: name })
                    .then(response => {
                        if (response.result.success && response.result.code === 200) {
                            let jsonResponse = response;
                            console.log('processDeepModel', jsonResponse);

                            jsonResponse.workstationName = name;

                            setTimeout(() => {
                                self.showPreloader(false, '', () => {
                                    self.addResultToLocalStorage(jsonResponse);

                                    if (response.result.compliant) {
                                        const cmsWorkstationCurrentContent = self.props.workstationsContents.find((e) =>
                                            e.content.title === name
                                        );
                                        const cmsWorkstationCurrentContentIndex = self.props.workstationsContents.indexOf(cmsWorkstationCurrentContent);

                                        if (cmsWorkstationCurrentContentIndex + 1 === self.props.workstationsContents.length) {
                                            // const app = f7;
                                            // app.dialog.alert(localeStrings.last_station_message, localeStrings.last_station_title);

                                            self.setState({ workstationSuccessEvent: true, nextContent: null });
                                        }
                                        else {
                                            const nextContent = self.props.workstationsContents[cmsWorkstationCurrentContentIndex + 1];
                                            self.setState({ workstationSuccessEvent: true, nextContent: nextContent });
                                        }
                                    }
                                    else {
                                        self.goToChecklistResult(jsonResponse);
                                    }
                                });
                            }, 200);
                        }
                        else {
                            self.showPreloader(false);
                            processDeepModelError(localeStrings.server_image_alignment_error_text);
                        }
                    }).catch(error => {
                        self.showPreloader(false);
                        processDeepModelError(localeStrings.sending_image_to_server_error_text);
                    })

                const processDeepModelError = (message) => {
                    const app = f7;
                    app.dialog.alert(message, localeStrings.image_loading_text_title);
                }
            });
        });
    }

    animationEnded = () => {
        const self = this;
        setTimeout(function () {
            if (self.state.nextContent)
                self.props.router.navigate(
                    Commons.getContentDetailUrl(self.state.nextContent.content),
                    {
                        reloadCurrent: true,
                        props:
                        {
                            contentItem: self.state.nextContent
                        },
                        animate: true
                    });
        }, this.state.animationClosingTime)
    }

    getContentAsBase64(fileUrl) {
        // this.getFileEntry(fileUrl);

        const importFile = (file, project) => {
            var reader = new FileReader();
            reader.onloadend = function (e) {
                // do your parsing here
            };
            reader.readAsText(file);
        }

        window.resolveLocalFileSystemURL(fileUrl,
            function (entry) {
                entry.file(
                    function (file) {
                        console.log("FileEntry.file success: " + file);
                        importFile(file);
                    },
                    function (error) {
                        console.log("FileEntry.file error: " + error.code);
                    }
                );
            },
            function (error) {
                console.log("resolveLocalFileSystemURL error: " + error.code);
            });
    }

    goToChecklistResult = (result) => {
        this.props.router.navigate('/checklist-result/', { reloadCurrent: true, props: { checklistResult: result }, animate: true });
    }

    addResultToLocalStorage = (result) => {
        const checklistResultListLocalStorage = localStorage.getItem('checklist_results_list');
        if (checklistResultListLocalStorage) {
            let checklistResultsJson = JSON.parse(checklistResultListLocalStorage);
            checklistResultsJson.unshift(result);

            if (checklistResultsJson.length > 2)
                checklistResultsJson.pop();

            localStorage.setItem('checklist_results_list', JSON.stringify(checklistResultsJson))
        }
        else {
            localStorage.setItem('checklist_results_list', "[" + JSON.stringify(result) + "]");
        }
    }

    showPreloader = (show, text, actionExecuted) => {
        const app = f7;

        if (show) {
            app.dialog.preloader(text, 'black');
            if (actionExecuted) {
                setTimeout(function () {
                    actionExecuted();
                }, this.state.dialogOpeningTime)
            }
        }
        else {

            setTimeout(function () {
                app.dialog.close();
                if (actionExecuted)
                    actionExecuted();
            }, this.state.dialogClosingTime)
        }
    }

    componentDidMount = () => {

    }
};

// #region Redux
const mapStateToProps = state => {
    return {
        menu: state.app.menu,
        workstationsContents: state.app.workstations,
    };
};

const mapDispatchToProps = dispatch => {
    return {
    }
};

// #endregion


export default connect(mapStateToProps, mapDispatchToProps)(ChecklistAutomationView);
import { PkApiContacts } from "../../api/contacts";
import { PkApiCalls } from "../../api/calls/calls";
import { Pk } from "../../index";
import { PkLog } from "../../log";
import { PkStore } from "../../redux/store";
import { PkReduxActions } from "../../redux/actions";
import { PkApiNet } from "../../api/net";

export class PkApiCallsParticipants {
  static startToPartecipate = (call) => {
    PkLog.log("[startToPartecipate]");
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "post",
        url:
          Pk.getConfig().apiUrl +
          "/services/calls/" +
          call.UniqueId +
          "/participants",
        data: new FormData(),
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          if (response.result.success === true) {
            PkLog.log(
              "[startToPartecipate] success - response.data.CallTargets:",
              response.data.CallTargets
            );
            PkStore.get().dispatch(
              PkReduxActions.setActiveCall(
                {
                  CallToken: response.data.Token,
                  Call: call,
                  AnnotationsColor: response.data.AnnotationsColor,
                },
                response.data.CallTargets
              )
            );
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };
  static stopPartecipation = () => {
    return new Promise((resolve, reject) => {
      if (!PkApiCalls.getActiveCall()) {
        resolve(null);
      } else {
        PkApiNet.callApi({
          method: "delete",
          url:
            Pk.getConfig().apiUrl +
            "/services/calls/" +
            PkApiCalls.getActiveCall().UniqueId +
            "/participants",
          data: new FormData(),
          config: {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          },
        })
          .then(function (response) {
            resolve(response);
          })
          .catch(function (errorMessage) {
            reject(new Error(errorMessage));
          });
      }
    });
  };

  static getCallParticipants = (callId) => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "get",
        url:
          Pk.getConfig().apiUrl + "/services/calls/" + callId + "/participants",
      })
        .then(function (response) {
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static isAssistedCallParticipant = (contactId) => {
    //  PkLog.error('[isAssistedCallParticipant] ' + contactId);
    let isAssisted = false;
    let activeCallTargets = PkStore.getState().activeCallTargets;
    PkLog.log(
      "[isAssistedCallParticipant] activeCallTargets.length=" +
      activeCallTargets.length,
      PkStore.getState().activeCallTargets
    );

    if (activeCallTargets && activeCallTargets.length > 0) {
      for (let i = 0; i < activeCallTargets.length; i++) {
        let contact = activeCallTargets[i].Contact;

        //     PkLog.log('[isAssistedCallParticipant] contact.UniqueId=' + contact.UniqueId);

        if (contact && contact.UniqueId === contactId) {
          if (PkApiContacts.isAssistedContact(contact)) {
            isAssisted = true;
            break;
          }
        }
      }
    }

    return isAssisted;
  };
}

export default PkApiCallsParticipants;

import React from 'react';
import ReactDOM from 'react-dom';
import Framework7 from 'framework7/lite-bundle';
import Framework7React from 'framework7-react';
import AppBoot from './appboot.jsx';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import store from './redux/store';

Framework7.use(Framework7React);

// Mount React App
ReactDOM.render(
  <Provider store={store}>
    <AppBoot />
  </Provider>,
  document.getElementById('app'),
);

// Trasforma .register in .unregister per disabilitare la PWA
if (!window.cordova || !window.cordova.version)
  serviceWorker.register();

var app = {
  // Application Constructor
  initialize: function () {
    /*********************************
     * INIZIALIZZO I MODULI PIKKART
     ********************************/

    if (window.cordova && window.cordova.version) {
      console.log("c'è cordova");
      //store.dispatch(RaReduxActions.setCordovaReady(false));
    } else {
      console.log("non c'è cordova");
    }


    /*********************************
     * GESTIONE USCITA DALLA PAGINA
     ********************************/
    window.addEventListener('beforeunload', (event) => {
      if (false) {
        event.returnValue = 'There is pending work. Sure you want to leave?';
      }
      window.focus();
    });

    /****************************
     * GESTIONE NOTIFICHE
     ***************************/
    /*if (!PkCordova.isCordova()) {
      PkNotifications.initWebNotifications(initializedFirebaseApp, process.env.FIREBASE_WEB_KEY);
      //if (RaApiAuth.isAuthenticated()) {
      PkNotifications.checkServiceRegistration().catch(function (errorMessage) {
        console.log('checkServiceRegistration error', errorMessage);
      });
      //}
    }*/

  }
};

app.initialize();
import { isIOS } from 'react-device-detect';

export class PkCordovaPermissions {

  static askCallPermissions = () => {

    return new Promise((resolve, reject) => {

      if (isIOS) {
        resolve();
      }
      else {
        var permissions = window.cordova.plugins.permissions;
        // TODO assicurarsi che siano gli stessi permessi inseriti nel manifest
        var list = [
          permissions.CAMERA,
          permissions.RECORD_AUDIO,
          /*       permissions.WRITE_EXTERNAL_STORAGE,
                 permissions.READ_EXTERNAL_STORAGE,
                 permissions.ACCESS_NETWORK_STATE,
                 permissions.INTERNET,
                 permissions.ACCESS_WIFI_STATE,
                 permissions.CHANGE_WIFI_STATE,
                 permissions.ACCESS_COARSE_LOCATION,
                 permissions.NETWORK_STATE*/
        ];

        permissions.checkPermission(list, success, error);

        function error(msg) {
          reject('Permissions are not turned on', msg);
        }

        function success(status) {
          if (!status.hasPermission) {
            permissions.requestPermissions(
              list,
              function (status) {
                if (!status.hasPermission) error();
                else {
                  resolve(status);
                }
              },
              error);
          }
        }
      }
    });
  }

  static askCameraPermission = () => {
    return new Promise((resolve, reject) => {

      var permissions = window.cordova.plugins.permissions;
      // TODO assicurarsi che siano gli stessi permessi inseriti nel manifest
      var list = [
        permissions.CAMERA,
      ];

      permissions.checkPermission(list, success, error);

      function error() {
        reject('Permissions are not turned on');
      }

      function success(status) {
        if (!status.hasPermission) {
          permissions.requestPermissions(
            list,
            function (status) {
              if (!status.hasPermission) error();
              else {
                resolve(status);
              }
            },
            error);
        }
      }
    });
  }

  static askMicPermission = () => {
    return new Promise((resolve, reject) => {

      var permissions = window.cordova.plugins.permissions;
      // TODO assicurarsi che siano gli stessi permessi inseriti nel manifest
      var list = [
        permissions.RECORD_AUDIO,
      ];

      permissions.checkPermission(list, success, error);

      function error() {
        reject('Permissions are not turned on');
      }

      function success(status) {
        if (!status.hasPermission) {
          permissions.requestPermissions(
            list,
            function (status) {
              if (!status.hasPermission) error();
              else {
                resolve(status);
              }
            },
            error);
        }
      }
    });
  }

  static askStoragePermission = () => {
    return new Promise((resolve, reject) => {

      var permissions = window.cordova.plugins.permissions;
      // TODO assicurarsi che siano gli stessi permessi inseriti nel manifest
      var list = [
        permissions.READ_EXTERNAL_STORAGE,
        permissions.WRITE_EXTERNAL_STORAGE,
      ];

      permissions.checkPermission(list, success, error);

      function error() {
        reject('Permissions are not turned on');
      }

      function success(status) {
        if (!status.hasPermission) {
          permissions.requestPermissions(
            list,
            function (status) {
              if (!status.hasPermission) error();
              else {
                resolve(status);
              }
            },
            error);
        }
      }
    });
  }

  static askArPermissions = () => {
    return new Promise((resolve, reject) => {

      var permissions = window.cordova.plugins.permissions;
      // TODO assicurarsi che siano gli stessi permessi inseriti nel manifest
      var list = [
        permissions.CAMERA,
        permissions.READ_EXTERNAL_STORAGE,
        permissions.WRITE_EXTERNAL_STORAGE,
      ];

      permissions.checkPermission(list, success, error);

      function error() {
        reject('Permissions are not turned on');
      }

      function success(status) {
        if (!status.hasPermission) {
          permissions.requestPermissions(
            list,
            function (status) {
              if (!status.hasPermission) error();
              else {
                resolve(status);
              }
            },
            error);
        }
      }
    });
  }

  static checkOverlayPermission = () => {
    return new Promise((resolve, reject) => {

      var permissions = window.cordova.plugins.permissions;

      permissions.checkOverlayPermission(success, error);

      function error() {
        reject('Overlay permission are not turned on');
      }

      function success(status) {
        resolve(status);
      }
    });
  }

  static askOverlayPermission = () => {
    return new Promise((resolve, reject) => {

      var permissions = window.cordova.plugins.permissions;

      permissions.requestOverlayPermission(success, error);

      function error() {
        reject('Permissions are not turned on');
      }

      function success(status) {
        if (!status.hasPermission) {
          error();
        }
        else {
          resolve(status);
        }
      }
    });
  }

  static areBackgroundServiceAndLockScreenNotificationPermissionsRequired = (manufacturer) => {
    return new Promise((resolve, reject) => {

      function error() {
        reject();
      }

      function success() {
        resolve()
      }
      var permissions = window.cordova.plugins.permissions;

      switch (manufacturer) {
        case permissions.ROM_360:
          success();
          break;
        case permissions.ROM_HUAWEI:
          success();
          break;
        case permissions.ROM_MEIZU:
          success();
          break;
        case permissions.ROM_MIUI:
          success();
          break;
        case permissions.ROM_OPPO:
          success();
          break;
        default:
          error();
          break;
      }
    });
  }

  static askOtherSettingsScreenPermission = (manufacturer) => {
    return new Promise((resolve, reject) => {

      var permissions = window.cordova.plugins.permissions;

      permissions.requestOtherSettingsScreenPermission(manufacturer, success, error);

      function error() {
        reject('Permissions are not turned on');
      }

      function success(status) {
        if (!status.hasPermission) {
          error();
        }
        else {
          resolve(status);
        }
      }
    });
  }
}


export default PkCordovaPermissions;


import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    gallery_title: "Gallery",
    start_ar_button: "Start",
    start_discover_button: "Start",
  },
  it: {
    gallery_title: "Galleria",
    start_ar_button: "Avvia",
    start_discover_button: "Avvia",
  }
});
  
  export default localeStrings;
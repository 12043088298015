import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        no_plugin_found_error: "Error in obtaining the image",
        sending_image_to_server_error_text: "Error loading the image, retry",
        server_image_alignment_error_text: "Image alignment error, please try again",
        image_loading_text_title: "Image error",
        last_station_message: "You checked the last station",
        last_station_title: "Last station",
    },
    it: {
        no_plugin_found_error: "Errore nell'ottenimento dell'immagine",
        sending_image_to_server_error_text: "Errore caricamento immagine, riprova",
        server_image_alignment_error_text: "Errore nell'allineamento dell'immagine, riprova",
        image_loading_text_title: "Errore immagine",
        last_station_message: "Hai effettuato il controllo dell'ultima stazione",
        last_station_title: "Ultima stazione",
    }
});

export default localeStrings;
import { PkReduxReducer } from "./reducer";

export class PkStore {
  static _store = null;

  static set = (store) => {
    this._store = store;
  };
  static get = () => {
    return this._store;
  };
  static getState = () => {
    return this.get().getState()[PkReduxReducer.getReducerName()];
  };
}

export default PkStore;


import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
  //la prima lingua è utilizzata come default
  en: {
    title: "Connection Error",
    text: "Make sure wifi or cellular data is turned on and then try again",
    button: "Reload",
  },
  it: {
    title: "Errore di Connessione",
    text: "Assicurati che la rete wifi o i dati cellulari siano attivi e riprova",
    button: "Riavvia",
  }
});

export default localeStrings;
import LocalizedStrings from 'react-localization';

const localeStrings = new LocalizedStrings({
    //la prima lingua è utilizzata come default
    en: {
        delete_content_option: "Delete",
        views_tab_title: 'Views',
        contents_tab_title: 'Contents',
        open_camera: 'Camera',
        open_gallery: 'Gallery',
        take_picture: 'Take Picture',
        process: 'Process',
        delete_view: 'Delete',
        view_tab_poi: 'POI',
        view_tab_mask: 'Mask',
        point_of_interest: 'Point of Interest',
        sheet_content_title: 'Content',
        sheet_search_placeholder: 'Search Content',
        color: 'Color',
        saving_poi_error: 'Saving Error',
        saving_mask_error: 'Saving Error',
        drawing_mask_error: 'Drawing mask error',
        process_model_error: 'Failed to process the model',
        process_model_success: 'Model processing success',
        mask_model_clear: 'Clear',
        mask_model_undo: 'Undo',
        mask_model_save: 'Save',
    },
    it: {
        delete_content_option: "Elimina",
        views_tab_title: 'Viste',
        contents_tab_title: 'Contenuti',
        open_camera: 'Fotocamera',
        open_gallery: 'Galleria',
        take_picture: 'Scatta Foto',
        process: 'Processa',
        delete_view: 'Elimina',
        view_tab_poi: 'POI',
        view_tab_mask: 'Maschera',
        point_of_interest: 'Punto di Interesse',
        sheet_content_title: 'Contenuto',
        sheet_search_placeholder: 'Cerca Contenuto',
        color: 'Colore',
        saving_poi_error: 'Errore nel salvataggio',
        saving_mask_error: 'Errore nel salvataggio',
        drawing_mask_error: 'Errore disegno maschera',
        process_model_error: 'Processo del modello fallito',
        process_model_success: 'Processo del modello riuscito',
        mask_model_clear: 'Pulisci',
        mask_model_undo: 'Indietro',
        mask_model_save: 'Salva',
    }
});

export default localeStrings;
import { PkApiCalls } from "../../../api/calls/calls";
import { PkLog } from "../../../log";
import { PkStore } from "../../../redux/store";
import { PkReduxActions } from "../../../redux/actions";
import { PkApiNet } from "../../../api/net";
import { PkCrypto } from "../../../crypto/crypto";
import { PkApiContacts } from "../../../api/contacts";
import { PkStorage } from "../../../storage";
import { Pk } from "../../../index";
import { PkApiCallsCommunicationProvider } from "../../../api/calls/communicationProvider";

export class PkApiCallsPics {
  static saveNewPic = (base64Url) => {
    return new Promise((resolve, reject) => {
      const bodyFormData = new FormData();
      let headerSeparator = base64Url.indexOf(",");
      if (headerSeparator) {
        base64Url = base64Url.substring(headerSeparator + 1);
      }
      let recipients = PkStore.getState().activeCallParticipants;
      recipients.push(
        PkApiContacts.getContactIdentities(
          PkStorage.getProfile().UniqueId,
          PkStorage.getProfile().PublicKey
        )
      );

      bodyFormData.set("picbase64", PkCrypto.encrypt(base64Url, recipients));

      bodyFormData.set("cryptoschema", Pk.getConfig().cryptoSchema);

      PkApiNet.callApi({
        method: "post",
        url:
          Pk.getConfig().apiUrl +
          "/services/calls/" +
          PkStore.getState().activeCall.Call.UniqueId +
          "/pics",
        data: bodyFormData,
        config: {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      })
        .then(function (response) {
          if (response.result.success === true) {
            const takenSnapshots = [
              ...PkStore.getState().takenSnapshots,
              response.data,
            ];
            PkStore.get().dispatch(
              PkReduxActions.setTakenSnapshots(takenSnapshots)
            );
            PkStore.get().dispatch(
              PkReduxActions.setNotSeenPhotos(takenSnapshots)
            );
            PkApiCallsPics.sendPicsChangedMessage();
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getActiveCallPics = () => {
    return new Promise((resolve, reject) => {
      PkApiCallsPics.getCallPics(PkStore.getState().activeCall.Call.UniqueId)
        .then(function (response) {
          if (response.result.success === true) {
            PkStore.get().dispatch(
              PkReduxActions.setTakenSnapshots(response.data)
            );
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getCallPics = (callUniqueId) => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "get",
        url:
          Pk.getConfig().apiUrl + "/services/calls/" + callUniqueId + "/pics",
      })
        .then(function (response) {
          if (response.result.success === true) {
            PkStore.get().dispatch(
              PkReduxActions.setTakenSnapshots(response.data)
            );
          }
          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static getPicBase64 = (callUniqueId, pic) => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "get",
        url:
          Pk.getConfig().apiUrl +
          "/storage/calls/" +
          callUniqueId +
          "/" +
          pic.ImageFileName,
      })
        .then(function (response) {
          if (response.result.success === true) {
            let cryptedFile = JSON.parse(atob(response.data.Base64));
            let fileBase64 = PkCrypto.decrypt(
              cryptedFile,
              response.data.CryptoSchema
            );
            resolve(fileBase64);
          } else {
            PkLog.error(response.result.message);
            resolve(null);
          }
        })
        .catch(function (errorMessage) {
          PkLog.error(errorMessage);
          resolve(null);
        });
    });
  };

  static selectPic = (pic, imgSrc) => {
    if (PkApiCalls.getActiveCall()) {
      PkApiCalls._onSelectedSnapshot(pic);
    }
    PkStore.get().dispatch(PkReduxActions.setSelectedSnapshot(pic, imgSrc));
  };

  static resetCallPics = () => {
    PkStore.get().dispatch(PkReduxActions.setSelectedSnapshot(null));
    PkStore.get().dispatch(PkReduxActions.setTakenSnapshots([]));
  };

  static sendSelectedPicMessage = (pic) => {
    if (PkApiCalls.amICallController() && pic) {
      PkApiCallsCommunicationProvider.sendMessage(
        '{"type": "onPicSelected", "data": ' + JSON.stringify(pic) + "}"
      );
    }
  };

  static sendSelectedPicRequestMessage = () => {
    if (!PkApiCalls.amICallController()) {
      PkApiCallsCommunicationProvider.sendMessage(
        '{"type": "askSelectedPic", "data": "" }'
      );
    }
  };

  static clearNotSeenPhotos = () => {
    PkStore.get().dispatch(PkReduxActions.setNotSeenPhotos([]));
  };

  static deletePic = (picId) => {
    return new Promise((resolve, reject) => {
      PkApiNet.callApi({
        method: "delete",
        url:
          Pk.getConfig().apiUrl +
          "/services/calls/" +
          PkStore.getState().activeCall.Call.UniqueId +
          "/pics/" +
          picId,
      })
        .then(function (response) {
          if (response.result.success === true) {
            let snapshots = PkStore.getState().takenSnapshots;
            PkStore.getState().takenSnapshots.forEach((snapshot, index) => {
              if (snapshot.UniqueId === picId) {
                if (index !== -1) {
                  snapshots.splice(index, 1);
                }
                PkStore.get().dispatch(
                  PkReduxActions.setDeletedSnapshot(snapshot)
                );
                if (
                  PkStore.getState().selectedSnapshot &&
                  PkStore.getState().selectedSnapshot.UniqueId ===
                  snapshot.UniqueId
                )
                  PkApiCallsPics.selectPic(null);
              }
            });
            PkStore.get().dispatch(PkReduxActions.setTakenSnapshots(snapshots));
            PkApiCallsPics.sendPicsChangedMessage();
          }

          resolve(response);
        })
        .catch(function (errorMessage) {
          reject(new Error(errorMessage));
        });
    });
  };

  static sendPicsChangedMessage = () => {
    PkApiCallsCommunicationProvider.sendMessage(
      '{"type": "pic", "data": ' +
      JSON.stringify(PkStore.getState().takenSnapshots) +
      "}"
    );
  };
}
export default PkApiCallsPics;

import React from 'react';
import { connect } from 'react-redux';
import { Block, Page, Icon, Button } from 'framework7-react';
import { AnalyticsRegister, ANALYTICS_SCREEN } from '../analytics-register';
import localeStrings from '../error-no-connection/error-no-connection-local';

class ErrorNoConnection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    } render() {
        // let menuItem = Commons.getMenuItemFromRoute(this.props.f7route, this.props.menu);
        return (
            <Page
                pageContent={false}
                colorTheme="custom"
                style={{
                    position: 'relative'
                }}>

                <Block
                    style={{
                        width: '100%',
                        textAlign: 'center',
                        margin: 0,
                        position: 'absolute',
                        top: '50%',
                        msTransform: 'translateY(-50%)',
                        transform: 'translateY(-50%)'
                    }}>
                    <div>
                        <Icon
                            size="100px"
                            ios="f7:exclamationmark_triangle"
                            aurora="f7:exclamationmark_triangle"
                            md="material:sentiment_very_dissatisfied" />
                        <h1>{localeStrings.title}</h1>
                        <p
                            style={{ marginBottom: '110px' }}>{localeStrings.text}</p>
                        <Button
                            large
                            fill
                            style={{ display: 'inline-block', marginTop: '20px' }}
                            reloadAll={true}
                            external={true}
                            view={'.view-main'}
                            onClick={() => {
                                if (window) {
                                    if (window.location) {
                                        window.location.reload()
                                    }
                                }
                            }}>
                            <Icon
                                ios="f7:arrow_clockwise"
                                aurora="f7:arrow_clockwise"
                                md="material:refresh" />
                            <span
                                style={{ marginLeft: '10px' }}>
                                {localeStrings.button}
                            </span>
                        </Button>
                    </div>
                </Block>
            </Page>);
    }

    componentDidMount = () => {
        AnalyticsRegister.setCurrentScreen(ANALYTICS_SCREEN.NO_CONNECTION);
    }
};

// #region Redux
const mapStateToProps = state => {
    return {

    };
};

const mapDispatchToProps = dispatch => {
    return {

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorNoConnection);
import appActionTypes from './actionTypes';


export const setOrientation = (orientation) => {
  return {
    type: appActionTypes.SET_ORIENTATION,
    orientation: orientation,
  };
}

export const setNotificationServiceError = (notificationServiceError) => {
  return {
    type: appActionTypes.SET_NOTIFICATION_SERVICE_ERROR,
    notificationServiceError: notificationServiceError,
  }
}

export const setMainPageIndex = (mainPageIndex) => {
  return {
    type: appActionTypes.SET_MAINPAGE_INDEX,
    mainPageIndex: mainPageIndex,
  }
}

export const setMenu = (menu) => {
  return {
    type: appActionTypes.SET_MENU,
    menu: menu,
  }
}

export const setSkippedArLogoTutorial = (skippedArLogoTutorial) => {
  return {
    type: appActionTypes.SET_SKIPPED_AR_LOGO_TUTORIAL,
    skippedArLogoTutorial: skippedArLogoTutorial,
  }
}

export const setSkippedDiscoverTutorial = (skippedDiscoverTutorial) => {
  return {
    type: appActionTypes.SET_SKIPPED_DISCOVER_TUTORIAL,
    skippedDiscoverTutorial: skippedDiscoverTutorial,
  }
}

export const setSkippedArSceneTutorial = (skippedArSceneTutorial) => {
  return {
    type: appActionTypes.SET_SKIPPED_AR_SCENE_TUTORIAL,
    skippedArSceneTutorial: skippedArSceneTutorial,
  }
}

export const setSkippedPlateSearchTutorial = (skippedPlateSearchTutorial) => {
  return {
    type: appActionTypes.SET_SKIPPED_PLATE_SEARCH_TUTORIAL,
    skippedPlateSearchTutorial: skippedPlateSearchTutorial
  }
}

export const setReceivedNotifications = (receivedNotifications) => {
  return {
    type: appActionTypes.SET_RECEIVED_NOTIFICATIONS,
    receivedNotifications: receivedNotifications,
  }
}

export const setFavoriteContents = (favoriteContents) => {
  return {
    type: appActionTypes.SET_FAVORITE_CONTENTS,
    favoriteContents: favoriteContents,
  }
}

export const setAcceptedTermsOfUse = (acceptedTermsOfUse) => {
  return {
    type: appActionTypes.SET_ACCEPTED_TERMS_OF_USE,
    acceptedTermsOfUse: acceptedTermsOfUse,
  }
}

export const setChosenResolutionIndex = (chosenResolutionIndex) => {
  return {
    type: appActionTypes.SET_CHOSEN_RESOLUTION_INDEX,
    chosenResolutionIndex: chosenResolutionIndex,
  }
}

export const setFocusQualityTolerance = (focusQualityTolerance) => {
  return {
    type: appActionTypes.SET_FOCUS_QUALITY_TOLERCANCE,
    focusQualityTolerance: focusQualityTolerance,
  }
}

export const setImageCroppingCompleted = (imageCroppingCompleted) => {
  return {
    type: appActionTypes.SET_IMAGE_CROPPING_COMPLETED,
    imageCroppingCompleted: imageCroppingCompleted,
  }
}

export const setActivityPaused = (activityPaused) => {
  return {
    type: appActionTypes.SET_ACTIVITY_PAUSED,
    activityPaused: activityPaused,
  }
}

export const setCurrentFilteredCustomer = (currentFilteredCustomer) => {
  return {
    type: appActionTypes.SET_CURRENT_FILTERED_CUSTOMER,
    currentFilteredCustomer: currentFilteredCustomer,
  }
}

export const setOnlyPrivateContentsVisible = (onlyPrivateContentsVisible) => {
  return {
    type: appActionTypes.SET_ONLY_PRIVATE_CONTENTS_VISIBLE,
    onlyPrivateContentsVisible: onlyPrivateContentsVisible,
  }
}

export const setSubMenuToOpen = (subMenu) => {
  return {
    type: appActionTypes.SET_SUB_MENU_TO_OPEN,
    subMenu: subMenu,
  }
}

export const setHasToReactivateCamera = (hasToReactivateCamera) => {
  return {
    type: appActionTypes.SET_HAS_TO_REACTIVATE_CAMERA,
    hasToReactivateCamera: hasToReactivateCamera,
  }
}

export const setWorkstations = (workstations) => {
  return {
    type: appActionTypes.SET_WORKSTATIONS,
    workstations: workstations,
  }
}

export const setMenuVisible = (menuVisible) => {
  return {
    type: appActionTypes.SET_MENU_VISIBLE,
    menuVisible: menuVisible,
  }
}

export const setActiveCodePage = (activeCodePage) => {
  return {
    type: appActionTypes.SET_ACTIVE_CODE_PAGE,
    activeCodePage: activeCodePage,
  }
}